/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import moment from 'moment';

import {Button, Col, Input, Row, Table, Tag} from 'antd';

import {
  faCircle,
  faExclamationCircle,
  faLocationDot,
  faLock,
  faPlus,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  findDevices,
  changeDeviceLightColor,
  sendDeviceCommand,
} from '../../services/devices';

import styles from './index.module.scss';
import Loading from '../../components/Loading';
import RowByStatus from '../../components/RowByStatus';
import stylesLayout from '../../common/layout.module.scss';
import stylesCommon from '../../common/common.module.scss';

import {networks} from '../../helpers/params';

const DevicesModule = ({
  // eslint-disable-next-line no-unused-vars
  fleets,
  user,
  fleetId,
}) => {
  const {refs} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [q, setQ] = useState(
    refs || localStorage.getItem('__devices__q') || ''
  );
  const [devices, setDevices] = useState(null);
  const [notFoundDevices, setNotFoundDevices] = useState(null);
  const [selectedRefs, setSelectedRefs] = useState([]);

  useEffect(() => {
    localStorage.setItem('__devices__q', q);
  }, [q]);

  const onStartSearch = async (forceQ) => {
    const searchQ = (forceQ || q).replaceAll('\n', ',').replaceAll(' ', ',');
    setLoading(true);
    setNotFoundDevices(null);
    setSelectedRefs([]);
    const newUrl = `/devices/${searchQ}`;
    if (newUrl !== history.location.pathname) {
      history.push(newUrl);
    }
    const rsp = await findDevices({
      q: searchQ,
      fleetId,
    });
    if (rsp && rsp.status) {
      const qrFound = rsp.data.map((x) => x.qr && x.qr.toUpperCase());
      const refFound = rsp.data.map((x) => x.ref && x.ref.toUpperCase());
      setNotFoundDevices(
        searchQ
          .split(',')
          .map((sq) =>
            qrFound.includes(sq.toUpperCase()) ||
            refFound.includes(sq.toUpperCase())
              ? null
              : sq
          )
          .filter((x) => !!x)
      );
      setDevices(
        rsp.data.map((x) => ({
          ...x,
          has_iot: x.data.iot_type?.toLowerCase() !== 'no',
        }))
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    onStartSearch();
  }, [fleetId]);
  useEffect(() => {
    if (refs && refs !== q) {
      setQ(refs);
      onStartSearch(refs);
    }
  }, [refs]);

  const deviceColumns = [
    {
      title: '#',
      key: 'index',
      render: (value, item, index) => 1 + index,
    },
    {
      title: 'Online (últ 30m)',
      dataIndex: 'is_online_last_30m',
      key: 'is_online_last_30m',
      align: 'center',
      render: (isOnlineLast30m) => (
        <FontAwesomeIcon
          icon={faCircle}
          style={{color: isOnlineLast30m ? '#093' : '#c30'}}
        />
      ),
    },
    {
      title: 'Bloqueo',
      dataIndex: 'data',
      key: 'locked',
      align: 'center',
      render: (data) => (
        <>
          <FontAwesomeIcon
            icon={data.locked === 1 ? faLock : faUnlock}
            style={{marginLeft: 5}}
            className={
              data.locked ? stylesCommon.locked : stylesCommon.unlocked
            }
          />
        </>
      ),
    },
    {
      title: 'QR',
      dataIndex: 'qr',
      align: 'center',
      key: 'qr',
      render: (qr, r) => <Link to={`/device/${r.ref}`}>{qr}</Link>,
      sorter: (a, b) => (a.qr < b.qr ? -1 : 1),
    },
    {
      title: 'MAC',
      align: 'left',
      dataIndex: 'ref',
      width: 200,
      key: 'ref',
      render: (ref, r) => (
        <>
          <Link to={`/device/${ref}`}>{ref}</Link>
          <br />
          {r.data?.tags?.map((x) => (
            <Tag key={x}>{x}</Tag>
          ))}
        </>
      ),
      sorter: (a, b) => (a.ref < b.ref ? -1 : 1),
    },
    {
      title: 'Status',
      dataIndex: 'status_tr',
      key: 'status_tr',
      filterMode: 'tree',
      filterSearch: true,
      filters: [...new Set(devices?.map((x) => x.status_tr))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, r) => r.status_tr === value,
      render: (status, r) => (
        <>
          {status}
          <br />
          <small>
            {r.data.last_status_change_at
              ? moment.utc(r.data.last_status_change_at).fromNow()
              : ''}
          </small>
        </>
      ),
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
    },
    {
      title: 'Tiene IOT?',
      align: 'center',
      dataIndex: 'has_iot',
      width: 200,
      key: 'has_iot',
      filterMode: 'tree',
      filterSearch: true,
      filters: [
        {text: 'SI', value: true},
        {text: 'NO', value: false},
      ],
      onFilter: (value, r) => r.has_iot === value,
      render: (v) => (v ? 'SI' : 'NO'),
    },
    {
      title: 'Posición',
      dataIndex: 'position',
      align: 'center',
      key: 'position',
      render: (p, r) => (
        <div className='flex flex-col gap-1'>
          {r.data.gps ? (
            <div className='flex gap-1 items-center'>
              <FontAwesomeIcon icon={faLocationDot} className='mr-2' />
              <span>
                <Link to={`/device/${r.data.gps}`}>{r.data.gps}</Link>
              </span>
            </div>
          ) : (
            <>
              <div>
                {r.lat}
                {', '}
                {r.lng}
              </div>
              <div>
                <small>{moment.utc(r.geo_updated_at).fromNow()}</small>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Últ Heartbeat',
      dataIndex: 'data',
      key: 'hb',
      align: 'center',
      render: (data) => (
        <small>
          {data.last_heartbeat_from_device_at ? (
            <>
              <FontAwesomeIcon
                icon={faCircle}
                style={{
                  color:
                    moment
                      .utc(data.last_heartbeat_from_device_at)
                      .diff(Date.now(), 'minutes') > -30
                      ? '#093'
                      : '#c30',
                }}
              />{' '}
              {moment.utc(data.last_heartbeat_from_device_at).fromNow()}
            </>
          ) : (
            ''
          )}
        </small>
      ),
      sorter: (a, b) =>
        moment(b.data.last_heartbeat_from_device_at).format('X') -
        moment(a.data.last_heartbeat_from_device_at).format('X'),
    },
    {
      title: 'Últ mensaje recibido',
      dataIndex: 'data',
      key: 'lm',
      defaultSortOrder: 'ascend',
      align: 'center',
      render: (data) => (
        <small>
          {data.last_message_from_device_at ? (
            <>
              <FontAwesomeIcon
                icon={faCircle}
                style={{
                  color:
                    moment
                      .utc(data.last_message_from_device_at)
                      .diff(Date.now(), 'minutes') > -30
                      ? '#093'
                      : '#c30',
                }}
              />{' '}
              {moment.utc(data.last_message_from_device_at).fromNow()}
            </>
          ) : (
            ''
          )}
        </small>
      ),
      sorter: (a, b) =>
        moment(b.data.last_message_from_device_at).format('X') -
        moment(a.data.last_message_from_device_at).format('X'),
    },
    {
      title: 'ICCID',
      dataIndex: 'data',
      align: 'center',
      key: 'iccid',
      render: (d) => d.iccid,
      sorter: (a, b) => (a.data.iccid < b.data.iccid ? -1 : 1),
    },
    {
      title: 'RSSI',
      dataIndex: 'data',
      align: 'center',
      key: 'rssi',
      render: (d) => d.rssi,
      sorter: (a, b) => (a.data.rssi < b.data.rssi ? -1 : 1),
    },
    {
      title: 'Versión Firmware',
      dataIndex: 'iot_firmware_version',
      key: 'iot_firmware_version',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [
        ...new Set(devices?.map((x) => x.data.iot_firmware_version)),
      ].map((x) => ({text: x || 'Sin info', value: x})),
      onFilter: (value, r) => r.iot_firmware_version === value,
      render: (v) =>
        v || <span style={{color: '#c30', fontWeight: 'bold'}}>Sin info</span>,
      sorter: (a, b) =>
        a.iot_firmware_version < b.iot_firmware_version ? -1 : 1,
    },
    {
      title: 'IOT conectado a scooter',
      dataIndex: 'is_scooter_present',
      key: 'is_scooter_present',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [
        {text: 'SI', value: '1'},
        {text: 'Desconocido', value: '0'},
      ],
      onFilter: (value, r) => r.is_scooter_present === value,
      render: (v) =>
        parseInt(v, 10) === 1 ? (
          <span style={{color: '#090', fontWeight: 'bold'}}>SI</span>
        ) : (
          <span style={{color: '#c30', fontWeight: 'bold'}}>Desconocido</span>
        ),
      sorter: (a, b) =>
        parseInt(a.is_scooter_present, 10) < parseInt(b.is_scooter_present, 10)
          ? -1
          : 1,
    },
    {
      title: 'HB Periodo',
      dataIndex: 'data',
      key: 'heartbeat_period',
      align: 'center',
      render: (data) => data.heartbeat_period,
    },
    {
      title: 'Vel máxima',
      dataIndex: 'max_speed',
      align: 'center',
      filterMode: 'tree',
      filterSearch: true,
      filters: [...new Set(devices?.map((x) => x.data.max_speed))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, r) => r.max_speed === value,
      render: (v) => v,
      sorter: (a, b) =>
        parseInt(a.data.max_speed, 10) < parseInt(b.data.max_speed, 10)
          ? -1
          : 1,
    },
    {
      title: 'Actualizado',
      dataIndex: 'updated_at',
      key: 'updated',
      align: 'center',
      render: (updatedAt) => moment.utc(updatedAt).fromNow(),
      sorter: (a, b) =>
        moment(b.updated_at).format('X') - moment(a.updated_at).format('X'),
    },
    {
      title: 'Batería Scooter',
      dataIndex: 'data',
      key: 'batsco',
      align: 'center',
      render: (data) =>
        `${data.batsco ? data.batsco : ''} ${data.charging === '1' ? '(C)' : ''}`,
      sorter: (a, b) =>
        // eslint-disable-next-line no-nested-ternary
        parseInt(a.data.batsco, 10) === parseInt(b.data.batsco, 10)
          ? 0
          : parseInt(a.data.batsco, 10) < parseInt(b.data.batsco, 10)
            ? -1
            : 1,
    },
    {
      title: 'Batería IOT',
      dataIndex: 'data',
      key: 'batiot',
      align: 'center',
      render: (data) =>
        `${data.batiot ? data.batiot : ''} ${data.charging === '1' ? '(C)' : ''}`,
      sorter: (a, b) =>
        // eslint-disable-next-line no-nested-ternary
        parseInt(a.data.batiot, 10) === parseInt(b.data.batiot, 10)
          ? 0
          : parseInt(a.data.batiot, 10) < parseInt(b.data.batiot, 10)
            ? -1
            : 1,
    },
    {
      title: '2G/4G (rssi)',
      dataIndex: 'data',
      key: '2g4g',
      align: 'center',
      render: (data) =>
        `${networks[data['4g2g']] ? networks[data['4g2g']] : ''} ${data.rssi ? `(${data.rssi})` : ''}`,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRefs(selectedRows.map((r) => r.ref));
    },
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      id: record.ref,
    }),
  };

  const changeSelectedToLight = async (color) => {
    setLoadingAction(true);
    await changeDeviceLightColor({
      ref: selectedRefs,
      type: 'scooter',
      mode: 1,
      color,
    });
    setLoadingAction(false);
  };

  const sendCommand = async (command) => {
    setLoadingAction(true);
    await sendDeviceCommand({
      ref: selectedRefs,
      type: 'scooter',
      command,
    });
    setLoadingAction(false);
  };
  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.title}>
        <div className={stylesLayout.secAction} style={{marginTop: -5}}>
          {user.can.manage_devices && (
            <Button
              onClick={() => history.push('/create_device')}
              size='medium'
            >
              <FontAwesomeIcon icon={faPlus} style={{marginRight: 5}} /> Crear
              patín
            </Button>
          )}
        </div>
        Patines
      </div>
      <div className={stylesLayout.content}>
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <div className={styles.input}>
              <Input
                value={q}
                placeholder='QR o MAC... (vacío para todos)'
                onChange={(e) => setQ(e.target.value)}
                onKeyDown={(e) => (e.key === 'Enter' ? onStartSearch() : null)}
              />

              <Button type='primary' onClick={() => onStartSearch()}>
                Buscar
              </Button>
            </div>
            {loading && <Loading />}
            {notFoundDevices && notFoundDevices.length > 0 && (
              <div style={{padding: '10px 0', color: '#c30'}}>
                <FontAwesomeIcon icon={faExclamationCircle} color='#c30' />{' '}
                {notFoundDevices.length} no encontrado
                {notFoundDevices.length !== 1 ? 's' : ''}
                {': '}
                {notFoundDevices.join(', ')}
              </div>
            )}
            {!loading && devices && devices.length > 0 && (
              <>
                <div className={styles.actions}>
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('off')}
                  >
                    Apagar luces
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('red')}
                  >
                    Poner rojo
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('yellow')}
                  >
                    Poner amarillo
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('green')}
                  >
                    Poner verde
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('blue')}
                  >
                    Poner azul
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('violet')}
                  >
                    Poner morado
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => changeSelectedToLight('pink')}
                  >
                    Poner rosa
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => sendCommand('50,0')}
                  >
                    Buzz Off
                  </Button>
                </div>
                <div className={styles.actions}>
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => sendCommand('211')}
                  >
                    Actualizar versión FW
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => sendCommand('201')}
                  >
                    Actualizar geolocalización
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => sendCommand('39,30')}
                  >
                    Heartbeat 30s
                  </Button>{' '}
                  <Button
                    type='primary'
                    disabled={
                      loadingAction ||
                      !selectedRefs ||
                      selectedRefs.length === 0
                    }
                    onClick={() => sendCommand('39,600')}
                  >
                    Heartbeat 10m
                  </Button>
                </div>

                <div className={styles.byStatus}>
                  <RowByStatus
                    devices={devices}
                    size='small'
                    cols={isMobile() ? 4 : 6}
                  />
                </div>
                <div className={styles.table}>
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    loading={loading}
                    size='small'
                    pagination={{
                      hideOnSinglePage: true,
                      defaultPageSize: 200,
                      pageSizeOptions: [10, 20, 50, 100, 200, 500],
                    }}
                    locale={{emptyText: 'Sin datos'}}
                    columns={deviceColumns}
                    dataSource={devices.map((d) => ({
                      key: d.id,
                      fleet_name: d.fleet?.name || '',
                      max_speed: d.data.max_speed,
                      is_scooter_present: d.data.is_scooter_present,
                      iot_firmware_version: d.data.iot_firmware_version,
                      ...d,
                    }))}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

DevicesModule.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  tenant: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default DevicesModule;
