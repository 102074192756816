/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// import { useSearchableTable } from '../../hooks/useSearchableTable';

import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {Badge, Button, Tabs, notification} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as subscriptionsService from '../../services/subscriptions';

import stylesLayout from '../../common/layout.module.scss';
import SubscriptionsTable from '../../components/SubscriptionsTable';
import Loading from '../../components/Loading';

import styles from './index.module.scss';

const {TabPane} = Tabs;

const SubscriptionsModule = ({selectedFleet, user}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPaidSubscriptions, setSelectedPaidSubscriptions] = useState(
    []
  );
  // eslint-disable-next-line no-unused-vars
  const [subscriptionsByStatus, setSubscriptionsByStatus] = useState(null);

  const load = async (showLoading) => {
    if (showLoading) {
      setLoading(true);
    }
    const [
      rspNonPaidSubscriptions,
      rspRenewalsCompletePaymentSubscriptions,
      rspPaidSubscriptions,
      rspReadyForShippingSubscriptions,
      rspPickupRequiredSubscriptions,
      rspStolenSubscriptions,
      rspMisappropriationSubscriptions,
    ] = await Promise.all([
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        renewal_status: 'NON_PAID',
        status: 'NON_PAID',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        renewal_status: 'COMPLETE_PAYMENT',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        status: 'PAID',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        status: 'READY_FOR_SHIPPING',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        status: 'PICKUP_REQUIRED',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        status: 'STOLEN',
      }),
      subscriptionsService.find({
        fleet_id: selectedFleet.id,
        status: 'MISAPPROPRIATION',
      }),
    ]);
    const rspSubscriptionsByStatus = {};

    if (rspNonPaidSubscriptions?.status) {
      rspSubscriptionsByStatus.non_paid = rspNonPaidSubscriptions.data;
    }
    if (rspRenewalsCompletePaymentSubscriptions?.status) {
      rspSubscriptionsByStatus.complete_payment =
        rspRenewalsCompletePaymentSubscriptions.data;
    }
    if (rspPaidSubscriptions?.status) {
      rspSubscriptionsByStatus.paid = rspPaidSubscriptions.data;
    }
    if (rspReadyForShippingSubscriptions?.status) {
      rspSubscriptionsByStatus.ready_for_shipping =
        rspReadyForShippingSubscriptions.data;
    }
    if (rspPickupRequiredSubscriptions?.status) {
      rspSubscriptionsByStatus.pickup_required =
        rspPickupRequiredSubscriptions.data;
    }
    if (rspStolenSubscriptions?.status) {
      rspSubscriptionsByStatus.stolen = rspStolenSubscriptions.data;
    }
    if (rspMisappropriationSubscriptions?.status) {
      rspSubscriptionsByStatus.misappropriation =
        rspMisappropriationSubscriptions.data;
    }

    setSubscriptionsByStatus(rspSubscriptionsByStatus);
    setLoading(false);
  };

  const setSubscriptionsStatus = async (ids, status) => {
    notification.info({
      message: 'Cambiando estado de suscripciones...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await subscriptionsService.setMultipleStatus(ids, status);
    if (rsp?.status) {
      notification.success({
        message: 'Estado de suscripciones cambiado.',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
      load(true);
    } else {
      notification.error({
        message: 'Error al cambiar estado de suscripciones.',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
  };

  useEffect(() => {
    load(true);
  }, [selectedFleet?.id]);

  return (
    <div className={`${stylesLayout.page}`}>
      <div className={stylesLayout.title}>
        Suscripciones
        <div className={stylesLayout.secAction} style={{marginTop: -5}}>
          <Button
            onClick={() => {
              load(true);
            }}
            size='medium'
          >
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Button>
        </div>
      </div>
      <div className={stylesLayout.content}>
        {!loading ? (
          <>
            <Tabs>
              {user.can.executive_grin4u && (
                <TabPane
                  tab={
                    <>
                      No pagadas{' '}
                      <Badge
                        showZero
                        count={subscriptionsByStatus?.non_paid.length || 0}
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='non_paid'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.non_paid}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
              {user.can.validate_payment_grin4u && (
                <TabPane
                  tab={
                    <>
                      Pagos pendientes de confirmación{' '}
                      <Badge
                        showZero
                        count={
                          subscriptionsByStatus?.complete_payment.length || 0
                        }
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='complete_payment'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.complete_payment}
                    fleet={selectedFleet}
                    show={['user', 'payment_completed_at']}
                  />
                </TabPane>
              )}
              {(user.can.executive_grin4u ||
                user.can.operations_grin4u ||
                user.can.validate_payment_grin4u) && (
                <TabPane
                  tab={
                    <>
                      Pagadas pendientes de entrega{' '}
                      <Badge
                        showZero
                        count={subscriptionsByStatus?.paid.length || 0}
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='paid'
                >
                  <div className={styles.tools}>
                    <Button
                      type='secondary'
                      onClick={() => {
                        setSubscriptionsStatus(
                          selectedPaidSubscriptions,
                          'READY_FOR_SHIPPING'
                        );
                      }}
                      disabled={!selectedPaidSubscriptions?.length}
                    >
                      Marcar como listas para entregar
                    </Button>
                  </div>
                  <SubscriptionsTable
                    rowSelectable
                    onSelectRows={(values) => {
                      setSelectedPaidSubscriptions(values);
                    }}
                    subscriptions={subscriptionsByStatus?.paid}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
              {(user.can.executive_grin4u ||
                user.can.operations_grin4u ||
                user.can.validate_payment_grin4u) && (
                <TabPane
                  tab={
                    <>
                      Listas para entregar{' '}
                      <Badge
                        showZero
                        count={
                          subscriptionsByStatus?.ready_for_shipping.length || 0
                        }
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='ready_for_shipping'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.ready_for_shipping}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
              {user.can.executive_grin4u && (
                <TabPane
                  tab={
                    <>
                      Pendientes de Recojo{' '}
                      <Badge
                        showZero
                        count={
                          subscriptionsByStatus?.pickup_required.length || 0
                        }
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='pickup_required'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.pickup_required}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
              {user.can.executive_grin4u && (
                <TabPane
                  tab={
                    <>
                      Apropiación ilícita{' '}
                      <Badge
                        showZero
                        count={
                          subscriptionsByStatus?.misappropriation.length || 0
                        }
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='misappropriation'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.misappropriation}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
              {user.can.executive_grin4u && (
                <TabPane
                  tab={
                    <>
                      Robadas{' '}
                      <Badge
                        showZero
                        count={subscriptionsByStatus?.stolen.length || 0}
                        color='var(--mainColor)'
                        overflowCount={999}
                      />
                    </>
                  }
                  key='stolen'
                >
                  <SubscriptionsTable
                    subscriptions={subscriptionsByStatus?.stolen}
                    fleet={selectedFleet}
                    show={['user']}
                  />
                </TabPane>
              )}
            </Tabs>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

SubscriptionsModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default SubscriptionsModule;
