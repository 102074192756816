import React from 'react';

// @ts-expect-error
import stylesLayout from '../../common/layout.module.scss';

type SectionTitleProps = {
  children: React.ReactNode;
};

const SectionTitle = ({children}: SectionTitleProps) => (
  <div className={stylesLayout.sectionHeader}>{children}</div>
);

export default SectionTitle;
