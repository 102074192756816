export default (client, defaultParams) => ({
  updateFirware: ({refs, type, binaryNumber}) =>
    client(`/device/${type}/${[...new Set(refs)].join(',')}/command`, {
      method: 'POST',
      data: `54,http://iot-file-server.grow.mobi,0,${binaryNumber},0,0,0,0,0,0`,
      params: {
        ...defaultParams,
      },
    }),
  findNonLocatable: (fleetId) =>
    client(`/devices/find/non_locatable?fleet_id=${fleetId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  find: ({
    q,
    fleetId,
    filters,
    position,
    radius = 10,
    output = 'full',
    status = null,
    type = null,
  }) =>
    client(
      `/devices/find?${type ? `type=${type}&` : ''}${status ? `status=${status.join(',')}&` : ''}filters=${filters ? JSON.stringify(filters) : null}&output=${output}&radius=${radius}&fleet_id=${fleetId || ''}&q=${q || ''}${position ? `&position=${position.lat},${position.lng}` : ''}`,
      {
        method: 'GET',
        params: {
          ...defaultParams,
        },
      }
    ),
  create: (payload) =>
    client('/device', {
      method: 'POST',
      data: payload,
      params: {
        ...defaultParams,
      },
    }),
  lock: (ref, type) =>
    client(`/device/${type}/${ref}/lock`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  unlock: (ref, type) =>
    client(`/device/${type}/${ref}/unlock`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  getBalanceSuggestions: (fleetId, hours, minProb) =>
    client(
      `/admin/devices/get_balance_suggestions/${fleetId}?hours_ahead=${hours}&min_prob=${minProb}`,
      {
        method: 'GET',
        params: {
          ...defaultParams,
        },
      }
    ),
  changeLightColor: (ref, type, mode, color) =>
    client(`/device/${type}/${ref}/lights/${mode}/${color}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  getByTypeQR: (qr, type) =>
    client(`/device/qr/${type}/${qr}?include_no_iot=1`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  entelInfo: (iccid) =>
    client(`/admin/device/entel/sim/${iccid}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  twilioInfo: (iccid) =>
    client(`/admin/device/twilio/sim/${iccid}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  getByTypeQRInFleet: (qr, type) =>
    client(`/device/qr/${type}/${qr}?only_in_fleet=1`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  getByTypeRef: (ref, type) =>
    client(`/device/ref/${type}/${ref}?include_no_iot=1`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  deleteByTypeRef: (ref, type) =>
    client(`/device/ref/${type}/${ref}`, {
      method: 'DELETE',
      params: {
        ...defaultParams,
      },
    }),
  setQr: (ref, qr) =>
    client(`/admin/device/${ref}/set_qr/${qr}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setSerial: (ref, type, value) =>
    client(`/admin/device/${ref}/set_serial/${type}/${value}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setFleet: (ref, fleetId) =>
    client(`/admin/device/${ref}/set_fleet/${fleetId}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setBrand: (ref, brand) =>
    client(`/admin/device/${ref}/set_brand/${brand}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  addDamage: (ref, data) =>
    client(`/admin/device/${ref}/damage`, {
      method: 'POST',
      data,
      params: {
        ...defaultParams,
      },
    }),
  fixDamage: (ref, hash, data) =>
    client(`/admin/device/${ref}/damage/${hash}/fix`, {
      method: 'PUT',
      data,
      params: {
        ...defaultParams,
      },
    }),
  addReparation: (ref, data) =>
    client(`/admin/device/${ref}/reparation`, {
      method: 'POST',
      data,
      params: {
        ...defaultParams,
      },
    }),

  setDeviceTags: (ref, tags) =>
    client(
      `/admin/device/${ref}/set_tags/${encodeURIComponent(tags.join(','))}`,
      {
        method: 'PUT',
        params: {
          ...defaultParams,
        },
      }
    ),
  setIot: (ref, newRef) =>
    client(`/admin/device/${ref}/set_iot/${newRef}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  removeIot: (ref) =>
    client(`/admin/device/${ref}/remove_iot`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setGPS: (ref, newGPS) =>
    client(`/admin/device/${ref}/set_gps/${newGPS}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setSIMPhoneNumber: (ref, newSIMPhoneNumber) =>
    client(`/admin/device/${ref}/set_sim_phone_number/${newSIMPhoneNumber}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  removeGPS: (ref) =>
    client(`/admin/device/${ref}/remove_gps`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  setPosition: (ref, lat, lng) =>
    client(`/admin/device/${ref}/set_position/${lat},${lng}`, {
      method: 'PUT',
      params: {
        ...defaultParams,
      },
    }),
  sendCommand: (ref, type, command, sync = false, useBackup = false) =>
    client(
      `/device/${type}/${ref}/${sync ? 'sync_' : ''}command?${useBackup ? 'use_backup=1' : ''}`,
      {
        method: 'POST',
        data: command,
        params: {
          ...defaultParams,
        },
      }
    ),
  sendSMSCommand: (ref, type, command) =>
    client(`/device/${type}/${ref}/sms_command/${command}`, {
      method: 'POST',
      params: {
        ...defaultParams,
      },
    }),
  log: (ref, type, limit = 200, beforeId = null) =>
    client(
      `/device/${type}/${ref}/log?limit=${limit}${beforeId ? `&before_id=${beforeId}` : ''}`,
      {
        method: 'GET',
        params: {
          ...defaultParams,
        },
      }
    ),
  events: (ref, type, limit = 200, beforeId = null) =>
    client(
      `/device/${type}/${ref}/events?limit=${limit}${beforeId ? `&before_id=${beforeId}` : ''}`,
      {
        method: 'GET',
        params: {
          ...defaultParams,
        },
      }
    ),
  geopositions: (ref, type, hours = 144) =>
    client(`/device/${type}/${ref}/geopositions?hours=${hours}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),
  statusTransitionsAllowed: (fleetId) =>
    client(`/devices/status_transitions_allowed?fleet_id=${fleetId}`, {
      method: 'GET',
      params: {
        ...defaultParams,
      },
    }),

  getMessage: (k) => {
    const messages = {
      '': '',
    };
    return messages[k] || k;
  },
});
