/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useParams} from 'react-router-dom';

import {Button, Input, notification, Space, Tabs} from 'antd';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import {
  faCheck,
  faCopy,
  faExclamationTriangle,
  faTimesCircle,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import CustomerGrin4UInfo from '../CustomerGrin4UInfo';
import CustomerSubscriptionsModule from '../CustomerSubscriptionsModule';
import Loading from '../../components/Loading';

import styles from './index.module.scss';
import stylesLayout from '../../common/layout.module.scss';

import {
  getById,
  modifyPassword,
  updateCountry,
  updatePhone,
  updateName,
  updatePhoto,
  setReferralCode,
} from '../../services/users';

import * as eventsService from '../../services/events';

import CopyWithMessage from '../../components/CopyWithMessage';
import {humanFileSize, pluralize} from '../../helpers/utils';
import UploaderFile from '../../components/UploaderFile';
import NotesListModule from '../NotesListModule';
import EventsList from '../../components/EventsList';

const {TabPane} = Tabs;

const CustomerModule = ({user, selectedFleet}) => {
  const {id: idParam} = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [newPhone, setNewPhone] = useState('');
  const [newName, setNewName] = useState('');
  const [newReferralCode, setNewReferralCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newCountry, setNewCountry] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const loadCustomer = async (id, showNotification) => {
    if (showNotification) {
      notification.info({
        message: 'Actualizando cliente...',
        placement: 'top',
        key: 'result',
        duration: 0,
      });
    }
    const [rspUser, rspEvents] = await Promise.all([
      getById(id),
      eventsService.getForItem('user', id),
    ]);

    if (rspUser && rspUser.status) {
      const newCustomer = rspUser.data;
      if (customer?.events) {
        newCustomer.events = customer.events;
      }
      if (customer?.customer_events) {
        newCustomer.customer_events = customer.customer_events;
      }

      if (rspEvents?.status) {
        newCustomer.events = rspEvents.data;
      }

      setCustomer(newCustomer);
      setNewName(newCustomer.name || ' ');
      setNewPhone(newCustomer.phone || ' ');
      setNewCountry(newCustomer.data?.country || ' ');
      setNewReferralCode(newCustomer.referral?.referral_code || ' ');

      if (showNotification) {
        notification.success({
          message: 'Cliente actualizado.',
          placement: 'top',
          key: 'result',
        });
      }
    } else if (showNotification) {
      notification.error({
        message: 'Error al actualizar cliente.',
        placement: 'top',
        key: 'result',
      });
    }
    setLoading(false);
  };

  const changePhone = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando teléfono...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePhone(customer.id, newPhone);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Teléfono cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar teléfono.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changeName = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando nombre...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateName(customer.id, newName);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Nombre cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar nombre.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const changeReferralCode = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando código de referido...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await setReferralCode(customer.id, newReferralCode);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Código de referido cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar código de referido.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const changeCountry = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando país...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updateCountry(customer.id, newCountry.trim());
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'País cambiado.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar país.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  const changePhoto = async (file) => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Guardando foto...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await updatePhoto(customer.id, file);
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Foto guardada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al guardar foto.',
        placement: 'top',
        key: 'result',
      });
    }
  };
  const changePassword = async () => {
    notification.destroy('in_progress');
    notification.destroy('result');
    notification.info({
      message: 'Actualizando contraseña...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    const rsp = await modifyPassword({
      user_id: customer.id,
      password: newPassword,
    });
    if (rsp?.status) {
      loadCustomer(customer.id);
      notification.success({
        message: 'Contraseña cambiada.',
        placement: 'top',
        key: 'result',
      });
    } else {
      notification.error({
        message: 'Error al cambiar contraseña.',
        placement: 'top',
        key: 'result',
      });
    }
  };

  useEffect(() => {
    loadCustomer(idParam);
  }, [idParam]);

  if (loading) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>
          <Loading />
        </div>
      </div>
    );
  }
  if (!customer) {
    return (
      <div className={stylesLayout.page}>
        <div className={stylesLayout.loading}>Cliente no encontrado</div>
      </div>
    );
  }

  const onUpdate = () => loadCustomer(customer.id, true);

  const createSubscriptionEnabled = customer.grin4u_status === 'ACTIVE';

  return (
    <div className={stylesLayout.page}>
      <div className={stylesLayout.breadcrumb}>
        <Link to='/clientes'>Clientes</Link> &raquo;
      </div>

      <div className={stylesLayout.title}>Cliente</div>
      <div className={stylesLayout.subtitle}>
        {customer.name} - {customer.email}
      </div>
      {customer.stolen_subscriptions?.length > 0 ? (
        <div
          style={{
            padding: 10,
            paddingLeft: 20,
            color: '#c30',
            background: '#fee',
            borderRadius: 5,
            marginBottom: 20,
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{marginRight: 10}}
          />
          <span style={{fontWeight: 'bold'}}>
            Este cliente tiene{' '}
            {pluralize(
              customer.stolen_subscriptions.length,
              'suscripción robada',
              'suscripciones robadas'
            )}
          </span>
          {customer.stolen_subscriptions.map((s) => (
            <span key={s.id} style={{marginLeft: 10}}>
              <Link to={`/suscripcion/${s.id}`}>#{s.id}</Link>
            </span>
          ))}
        </div>
      ) : null}
      {customer.misappropriation_subscriptions?.length > 0 ? (
        <div
          style={{
            padding: 10,
            paddingLeft: 20,
            color: '#ff9900',
            background: '#fed',
            borderRadius: 5,
            marginBottom: 20,
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{marginRight: 10}}
          />
          <span style={{fontWeight: 'bold'}}>
            Este cliente tiene{' '}
            {pluralize(
              customer.misappropriation_subscriptions.length,
              'suscripción con apropiación ilícita',
              'suscripciones con apropiación ilícita'
            )}
          </span>
          {customer.misappropriation_subscriptions.map((s) => (
            <span key={s.id} style={{marginLeft: 10}}>
              <Link to={`/suscripcion/${s.id}`}>#{s.id}</Link>
            </span>
          ))}
        </div>
      ) : null}

      <div className={stylesLayout.content}>
        <div className={stylesLayout.desktop_2cols400}>
          <div>
            <Tabs>
              <TabPane tab={<>Información del cliente</>} key='trips'>
                {!createSubscriptionEnabled && (
                  <div
                    style={{
                      marginBottom: 30,
                      marginTop: 10,
                      fontWeight: 'bold',
                      color: '#f90',
                      textAlign: 'left',
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} /> No puedes crear
                    suscripciones porque el cliente no tiene todos los datos
                    completos
                  </div>
                )}
                <table className={stylesLayout.infotable}>
                  {false && customer.is_internal && user.can.manage_users && (
                    <tr>
                      <td width='25%'>Contraseña</td>
                      <td>
                        <Space direction='horizontal'>
                          <Input.Password
                            autoComplete='off'
                            value={newPassword}
                            id='customer-new_p'
                            onChange={(e) => setNewPassword(e.target.value)}
                            style={{width: 300}}
                            visibilityToggle={{
                              visible: passwordVisible,
                              onVisibleChange: setPasswordVisible,
                            }}
                          />
                          {!!newPassword && (
                            <CopyWithMessage
                              inline
                              value={newPassword}
                              messageCopied='Contraseña copiada!'
                              messageTime={2000}
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                style={{fontSize: 16}}
                              />
                            </CopyWithMessage>
                          )}
                          <Button
                            onClick={changePassword}
                            disabled={!newPassword}
                          >
                            Guardar
                          </Button>
                        </Space>
                        <br />
                        <a
                          onClick={() => {
                            setPasswordVisible(true);
                            setNewPassword(
                              String(
                                parseInt(100000 + Math.random() * 999999, 10)
                              )
                            );
                          }}
                        >
                          Generar contraseña aleatoria
                        </a>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td width='25%'>Nombre</td>
                    <td>
                      <Space direction='horizontal' align='start'>
                        {user.can.executive_grin4u ? (
                          <div>
                            <Input
                              value={newName}
                              autoComplete='off'
                              id='customer-name'
                              onChange={(e) => setNewName(e.target.value)}
                              style={{width: 300}}
                            />
                          </div>
                        ) : (
                          newName
                        )}
                        {user.can.executive_grin4u && (
                          <Button
                            onClick={changeName}
                            disabled={newName === customer.name}
                          >
                            Guardar
                          </Button>
                        )}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td width='25%'>Email</td>
                    <td>{customer.email}</td>
                  </tr>
                  <tr>
                    <td width='25%'>Código de referido</td>
                    <td>
                      <Space direction='horizontal' align='start'>
                        {user.can.executive_grin4u ? (
                          <div>
                            <Input
                              value={newReferralCode}
                              autoComplete='off'
                              id='customer-name'
                              onChange={(e) =>
                                setNewReferralCode(e.target.value)
                              }
                              style={{width: 300}}
                            />
                          </div>
                        ) : (
                          newReferralCode ?? 'Sin código de referido'
                        )}
                        {user.can.executive_grin4u && (
                          <Button
                            onClick={changeReferralCode}
                            disabled={
                              newReferralCode ===
                              customer.referral?.referral_code
                            }
                          >
                            Guardar
                          </Button>
                        )}
                      </Space>
                    </td>
                  </tr>
                  <tr>
                    <td width='25%'>Fecha del lead</td>
                    <td>
                      {customer.data?.grin4uinfo?.lead_date
                        ? dayjs(customer.data?.grin4uinfo?.lead_date).format(
                            'YYYY-MM-DD'
                          )
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td width='25%'>Fecha de creación</td>
                    <td>
                      {moment
                        .utc(customer.created_at)
                        .local()
                        .format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                  </tr>

                  {customer.data.grin4uinfo?.created_at && (
                    <tr>
                      <td width='25%'>Creado como cliente Grin 4U</td>
                      <td>
                        {customer.data.grin4uinfo?.created_at
                          ? moment
                              .utc(customer.data.grin4uinfo?.created_at)
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss')
                          : ''}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td width='25%'>Status Grin 4U</td>
                    <td>{customer.grin4u_status_tr}</td>
                  </tr>

                  <tr>
                    <td width='25%'>Teléfono</td>
                    <td>
                      <Space direction='horizontal' align='start'>
                        {user.can.executive_grin4u ? (
                          <div>
                            <Input
                              value={newPhone}
                              autoComplete='off'
                              id='customer-tel'
                              onChange={(e) => setNewPhone(e.target.value)}
                              style={{width: 300}}
                            />
                          </div>
                        ) : (
                          newPhone
                        )}
                        {user.can.executive_grin4u && (
                          <Button
                            onClick={changePhone}
                            disabled={newPhone === customer.phone}
                          >
                            Guardar
                          </Button>
                        )}
                        {customer?.phone && (
                          <>
                            <a
                              href={`http://wa.me/${customer.phone.trim().replaceAll('+', '')}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <FontAwesomeIcon
                                icon={faWhatsapp}
                                style={{
                                  color: '#25D366',
                                  fontSize: 24,
                                  marginRight: 5,
                                }}
                              />
                            </a>
                          </>
                        )}
                      </Space>
                    </td>
                  </tr>

                  <tr>
                    <td width='25%'>País del usuario</td>
                    <td>
                      <Space direction='horizontal'>
                        {customer.data?.country}
                      </Space>
                    </td>
                  </tr>

                  <tr>
                    <td width='25%'>Foto</td>
                    <td>
                      <div>
                        <UploaderFile
                          onUpload={({file}) => {
                            changePhoto(file);
                          }}
                          render={({uploading, progress}) => (
                            <>
                              {!uploading && (
                                <div className={stylesLayout.uploader}>
                                  <div className={stylesLayout.uploaderInner}>
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      style={{marginRight: 10}}
                                    />{' '}
                                    Haz click o arrastra un nuevo archivo aquí
                                  </div>
                                </div>
                              )}
                              {uploading &&
                                progress &&
                                progress.map((p) => (
                                  <div className={styles.fileDetail}>
                                    <div
                                      className={styles.fileDetailProgress}
                                      style={{width: `${p.percent}%`}}
                                    />
                                    <div className={styles.fileDetailInner}>
                                      {p.percent >= 100 ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          color='#093'
                                          style={{
                                            marginRight: 10,
                                            color: '#093',
                                          }}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faUpload}
                                          color='#999'
                                          style={{
                                            marginRight: 10,
                                            color: '#999',
                                          }}
                                        />
                                      )}
                                      <div className={styles.fileDetailName}>
                                        <span
                                          style={{
                                            display: 'inline-block',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '80%',
                                          }}
                                        >
                                          {p.name}
                                        </span>
                                        {p.percent >= 100 && (
                                          <span> - Guardando...</span>
                                        )}
                                      </div>
                                      <div className={styles.fileDetailSize}>
                                        {humanFileSize(
                                          (p.size * p.percent) / 100
                                        )}{' '}
                                        de {humanFileSize(p.size)}
                                      </div>
                                      <div className={styles.fileDetailPercent}>
                                        {parseInt(p.percent, 10)}%
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
                <br />
                <br />
                <CustomerGrin4UInfo
                  customer={customer}
                  user={user}
                  onUpdate={onUpdate}
                />
              </TabPane>
              {createSubscriptionEnabled && (
                <TabPane tab={<>Suscripciones</>} key='subscriptions'>
                  <CustomerSubscriptionsModule
                    selectedFleet={selectedFleet}
                    customer={customer}
                    country={customer.data?.country}
                    user={user}
                    onUpdate={onUpdate}
                  />
                </TabPane>
              )}
              <TabPane tab={<>Historial</>} key='history'>
                <EventsList events={customer.events} />
              </TabPane>
            </Tabs>
          </div>
          <div>
            <div className={stylesLayout.sectionTitle}>Notas del cliente</div>
            <NotesListModule
              itemType='customer'
              itemId={customer.id}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerModule.propTypes = {
  selectedFleet: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default CustomerModule;
