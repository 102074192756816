/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const CopyWithMessage = ({
  value,
  children,
  messageCopied,
  messageTime,
  onCopied,
  inline,
  style,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const pid = window.setTimeout(() => {
        setCopied(false);
      }, messageTime);
      return () => {
        if (pid) {
          window.clearTimeout(pid);
        }
      };
    }
    return () => {};
  }, [copied]);

  return (
    <div
      className='flex gap-1 items-center cursor-pointer'
      style={{display: inline ? 'inline-flex' : 'block', ...style}}
    >
      <CopyToClipboard
        text={value}
        onCopy={() => {
          onCopied(value);
          setCopied(true);
        }}
      >
        <div>{children}</div>
      </CopyToClipboard>
      {copied && <div>{messageCopied}</div>}
    </div>
  );
};
CopyWithMessage.defaultProps = {
  value: '',
  messageCopied: 'Copiado',
  messageTime: 2000,
  onCopied: () => {},
  children: <></>,
  style: {},
  inline: false,
};

CopyWithMessage.propTypes = {
  value: PropTypes.string,
  messageCopied: PropTypes.string,
  messageTime: PropTypes.number,
  onCopied: PropTypes.func,
  children: PropTypes.any,
  style: PropTypes.object,
  inline: PropTypes.bool,
};

export default CopyWithMessage;
