/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form, Input, Button, Alert} from 'antd';

import styles from './index.module.scss';
import stylesForm from '../../common/form.module.scss';

import {login, forgetPassword} from './logic';

import {setUser as setUserAction} from '../Auth/actions';

const LoginModule = ({setUser, user}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState('login');
  const [error, setError] = useState();
  useEffect(() => {
    if (user && user.id && user.can) {
      history.push('/');
    }
  }, [user]);

  const doLogin = async (values) => {
    setLoading(true);
    setError(null);
    const rsp = await login({
      ...values,
    });
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setUser(rsp.data);
      history.push('/dashboard');
    }
    setLoading(false);
  };

  const doForgetPassword = async (values) => {
    setLoading(true);
    setError(null);
    const rsp = await forgetPassword({
      ...values,
    });
    if (rsp && !rsp.status) {
      setError(rsp.msg);
    }
    if (rsp && rsp.status) {
      setStage('forget_password_sent');
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    switch (stage) {
      case 'login': {
        return doLogin(values);
      }
      case 'forget_password': {
        return doForgetPassword(values);
      }
      default: {
        return null;
      }
    }
  };

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo);
  };
  if (stage === 'forget_password_sent') {
    return (
      <div className={styles.cnt}>
        <div className={styles.login}>
          <div className={`${styles.form} ${stylesForm.form}`}>
            <div className={styles.logo}>
              <img src='/android-chrome-192x192.png' alt='' />
            </div>
            <div className={stylesForm.fields}>
              Hemos enviado un correo electrónico a la dirección indicada con{' '}
              instrucciones para cambiar la contraseña.
              <br />
              <br />
              Por favor revisa tu bandeja de correo para continuar el proceso.
              <br />
              <br />
              <small>
                Ten en cuenta que a veces puedes demorar unos minutos en recibir
                el mail. También revisa tu bandeja de Spam o correo no deseado.
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.cnt}>
      <div className={styles.login}>
        <div className={`${styles.form} ${stylesForm.form}`}>
          <div className={styles.logo}>
            <img src='/android-chrome-192x192.png' alt='' />
          </div>
          {error && (
            <div className={stylesForm.error}>
              <Alert message={error} type='error' />
            </div>
          )}
          <div className={stylesForm.fields}>
            <Form
              name='basic'
              form={form}
              initialValues={{email: '', password: ''}}
              layout='vertical'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              size='large'
            >
              <Form.Item
                label='Email'
                name='email'
                rules={[{required: true, message: 'El email es requerido'}]}
              >
                <Input placeholder='Tu email' type='email' />
              </Form.Item>
              {stage === 'login' && (
                <>
                  <Form.Item
                    label='Contraseña'
                    name='password'
                    help='Debe tener al menos 6 caracteres'
                    rules={[
                      {required: true, message: 'La contraseña es requerido'},
                      {
                        min: 6,
                        message:
                          'La contraseña debe tener al menos 6 caracteres',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <div style={{display: 'none', marginBottom: 20}}>
                    <a onClick={() => setStage('forget_password')}>
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>
                </>
              )}
              <Form.Item>
                <Button type='primary' htmlType='submit' disabled={loading}>
                  {stage === 'login' ? 'Entrar' : 'Cambiar contraseña'}
                </Button>
                {stage === 'forget_password' && (
                  <a style={{marginLeft: 10}} onClick={() => setStage('login')}>
                    Volver
                  </a>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginModule.defaultProps = {
  user: null,
};

LoginModule.propTypes = {
  setUser: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserAction(user)),
});

export default connect(null, mapDispatchToProps)(LoginModule);
