/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {QrReader} from '@blackbox-vision/react-qr-reader';
import isMobile from 'is-mobile';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import {Button, Checkbox, Input, Modal, Select, notification} from 'antd';

import {ExclamationCircleOutlined} from '@ant-design/icons';
import {
  faCheck,
  faCopy,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faLightbulb,
  faLocationArrow,
  faQrcode,
  faTimes,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {distance} from '../../helpers/utils';
import {brands, statusTranslations} from '../../helpers/params';
import {useTorchLight} from '../../hooks/torch';
import {getGeofencesAndPointsForFleet} from '../../services/geofences';

import Loading from '../../components/Loading';
import RowByStatus from '../../components/RowByStatus';

import {login} from '../../services/users';

import {
  getDeviceByTypeQROrRef,
  getStatusTransitionsAllowed,
  sendDeviceCommand,
} from '../../services/devices';

import {setDevicesStatus} from '../../services/fleets';

import stylesLayout from '../../common/layout.module.scss';
import CopyWithMessage from '../../components/CopyWithMessage';
import {getUsersOfTenant} from '../../services/tenants';
import styles from './index.module.scss';

const nonValidUserEmails = ['generico.bodega@grow.mobi'];
const FleetManagementModule = ({tenant, position, fleets, fleetId, user}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [changeLocationOnUpdate, setChangeLocationOnUpdate] =
    useState(isMobile());
  const [scanning, setScanning] = useState(false);
  const [statusTransitionsAllowed, setStatusTransitionsAllowed] = useState({});
  const [showResume, setShowResume] = useState(false);
  const [qr, setQr] = useState();
  const qrInput = useRef(null);
  const streamRef = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [points, setPoints] = useState([]);
  const [selectedUserPassword, setSelectedUserPassword] = useState(null);
  const [users, setUsers] = useState(null);
  const [torchOn, torchToggle] = useTorchLight({
    onError: (e) => console.log('TORCH ERROR', e),
    onSuccess: (status) => console.log('TORCH OK', status),
  });
  const [scannedQrs, setScannedQrs] = useState([]);
  const scannedQrsRef = useRef(scannedQrs);
  scannedQrsRef.current = scannedQrs;

  const getTenantUsers = async () => {
    const rsp = await getUsersOfTenant();
    if (rsp?.status) {
      setUsers(rsp.data);
      if (!nonValidUserEmails.includes(user.email)) {
        setSelectedUserId(user.id);
      }
    } else {
      setUsers([]);
    }
  };

  const loadPoints = async () => {
    const rsp = await getGeofencesAndPointsForFleet([fleetId], ['ACTIVE']);
    if (rsp && rsp.status) {
      setPoints(rsp.data.points);
    } else {
      setPoints([]);
    }
  };

  const endScanning = () => {
    setScanning(false);
  };
  const startScanning = () => {
    setScanning(true);
  };
  const loadDevice = async (code, type) => {
    const rsp = await getDeviceByTypeQROrRef({
      type,
      code,
    });
    if (rsp && rsp.status) {
      const device = rsp.data;
      if (device.fleet_id !== fleetId) {
        Modal.error({
          width: 400,
          title: 'Error',
          content: `El dispositivo ${device.qr} no pertenece a la flota seleccionada`,
          icon: <ExclamationCircleOutlined />,
        });
        setScannedQrs((s) => {
          const newS = s.map((x) =>
            x.q === code ? {...x, status: 'no_in_selected_fleet'} : x
          );
          return newS;
        });
        return null;
      }
      if (!device.fleet.cost.managed_in_fleet_management) {
        Modal.error({
          width: 400,
          title: 'Error',
          content: `El dispositivo ${device.qr} no puede ser gestionado de esta forma`,
          icon: <ExclamationCircleOutlined />,
        });
        setScannedQrs((s) => {
          const newS = s.map((x) =>
            x.q === code ? {...x, status: 'notmanageable'} : x
          );
          return newS;
        });
        return null;
      }
      setScannedQrs((s) => {
        const newS = s.map((x) =>
          x.q === device.qr?.toUpperCase() || x.q === device.ref.toUpperCase()
            ? {
                ...x,
                status: fleets.map((xx) => xx.id).includes(device.fleet_id)
                  ? 'loaded'
                  : 'notinfleet',
                device,
              }
            : x
        );
        return newS;
      });
    } else {
      setScannedQrs((s) => {
        const newS = s.map((x) =>
          x.q === code ? {...x, status: 'notfound'} : x
        );
        return newS;
      });
    }
    return null;
  };

  const clearList = () => {
    setScannedQrs([]);
    setShowResume(false);
  };
  const removeScannedQr = (removeQr) => {
    setScannedQrs(scannedQrs.filter((x) => x.q !== removeQr));
  };
  const addToScannedQrs = (newQr) => {
    const newQrs = newQr
      .split(/,| |-/)
      .map((q) => {
        if (
          q &&
          !scannedQrsRef.current.map((x) => x.q).includes(q.toUpperCase())
        ) {
          return {q: q.trim().toUpperCase(), status: 'loading'};
        }
        return null;
      })
      .filter((x) => !!x);
    if (newQrs) {
      setScannedQrs([...scannedQrsRef.current, ...newQrs]);
      newQrs.map((x) => loadDevice(x.q, 'scooter'));
    }
  };
  const sendDevicesCommand = async (command) => {
    notification.info({
      message: 'Enviando comando de desbloqueo...',
      placement: 'top',
      key: 'result',
      duration: 0,
    });
    await sendDeviceCommand({
      ref: scannedQrs.filter((x) => x.device).map((x) => x.device.ref),
      type: 'scooter',
      command,
    });
    notification.success({
      message: 'Comando de desbloqueo enviado',
      placement: 'top',
      key: 'result',
    });
  };
  const moveDevicesToStatus = async (
    status,
    data = {},
    allowEvenIfNotUpdateLocation = false
  ) => {
    if (!selectedUserId) {
      Modal.error({
        width: 800,
        title: 'Error',
        content: 'Selecciona tu usuario',
        icon: <ExclamationCircleOutlined />,
      });
      return;
    }
    if (!allowEvenIfNotUpdateLocation && !position?.latitude) {
      Modal.error({
        width: 800,
        title: 'Error',
        content:
          'No es posible cambiar el estado del patín ya que el teléfono no reporta la posición actual',
        icon: <ExclamationCircleOutlined />,
      });
      return;
    }
    if (selectedUserId !== user.id) {
      if (!selectedUserPassword) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: 'Ingresa la contraseña del usuario',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }
      const rsp = await login({
        email: users.find((x) => x.id === selectedUserId).email,
        password: selectedUserPassword,
        noRedir: true,
      });
      if (!rsp.status) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: 'La contraseña del usuario es erronea',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }
    }
    if (
      ['in_transport_to_deploy', 'in_transport_to_test', 'live'].includes(
        status
      )
    ) {
      const devicesWithoutQR = scannedQrs
        .filter((x) => x.device)
        .filter(
          (x) =>
            [1, 6].includes(x.device.fleet_id) &&
            (!x.device.qr || x.device.qr === 'FFFF')
        );
      if (devicesWithoutQR && devicesWithoutQR.length > 0) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: 'No es posible sacar de bodega patines sin QR',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }
    }
    if (['in_transport_to_deploy', 'live'].includes(status)) {
      const devicesInFleetNoAccess = scannedQrs.filter(
        (x) => x.status === 'notinfleet'
      );
      const devicesWithDamages = scannedQrs
        .filter((x) => x.device)
        .filter(
          (x) =>
            [1, 6].includes(x.device.fleet_id) && x.device.has_active_damages
        );
      if (devicesWithDamages && devicesWithDamages.length > 0) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: 'No es posible poner en calle patines con daños',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }

      const devicesWithoutIOT = scannedQrs
        .filter((x) => x.device)
        .filter(
          (x) =>
            [1, 6].includes(x.device.fleet_id) &&
            x.device.data.iot_type?.toLowerCase() === 'no'
        );

      if (devicesWithoutIOT?.length > 0) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: 'No es posible poner en calle patines SIN IOT',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }

      const maxHours = fleetId === 1 ? 48 : 120;
      const devicesWithLastHbBefore = scannedQrs
        .filter((x) => x.device)
        .filter(
          (x) =>
            [1, 6].includes(x.device.fleet_id) &&
            x.device.data?.last_heartbeat_from_device_at <
              moment
                .utc()
                .subtract(maxHours, 'hours')
                .format('YYYY-MM-DD HH:mm:ss')
        );
      if (devicesInFleetNoAccess && devicesInFleetNoAccess.length > 0) {
        Modal.error({
          width: 800,
          title: 'Error',
          content:
            'No es posible hacer cambios en patines que no tienes acceso',
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }
      if (devicesWithLastHbBefore && devicesWithLastHbBefore.length > 0) {
        Modal.error({
          width: 800,
          title: 'Error',
          content: `No es posible este cambio de estado para patines con último HB anterior a ${maxHours} horas`,
          icon: <ExclamationCircleOutlined />,
        });
        return;
      }
    }
    const selectedFleet = fleets.find((x) => x.id === fleetId);
    if (
      selectedFleet.cost?.use_device_location &&
      !allowEvenIfNotUpdateLocation &&
      !changeLocationOnUpdate
    ) {
      Modal.confirm({
        width: 800,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            ¿Estás seguro que{' '}
            <strong>no quieres poner los patines en tu ubicación actual</strong>
            ?
          </div>
        ),
        okText: 'Si',
        okButtonProps: {danger: true},
        cancelText: 'No',
        onOk() {
          moveDevicesToStatus(status, data, true);
        },
        onCancel() {},
      });
      return;
    }

    setSaving(true);
    const rsp = await setDevicesStatus({
      devices: scannedQrs.filter((x) => x.device).map((x) => x.device.id),
      status,
      selectedUserId,
      passed_position: {
        lat: position.latitude,
        lng: position.longitude,
        x: 'a',
        st: !!position?.latitude,
      },
      setLocation: changeLocationOnUpdate,
      data,
    });
    if (rsp && rsp.status) {
      const newDevices = rsp.data;
      const newScannedQrs = scannedQrs.map((c) => {
        const newItem = c;
        if (newItem.device && c.device) {
          const f = newDevices.find((x) => x.device?.id === c.device.id);
          if (f) {
            newItem.device = f.device;
            newItem.change_status = f.status;
            newItem.change_message = f.error || f.message || '';
          }
        }
        return newItem;
      });
      setScannedQrs(newScannedQrs);
      setShowResume(true);
    }
    setSelectedUserPassword(null);
    setSaving(false);
  };

  const loadStatusTransitionsAllowed = async () => {
    const rsp = await getStatusTransitionsAllowed(fleetId);
    if (rsp?.status) {
      setStatusTransitionsAllowed(rsp.data);
    }
  };
  useEffect(() => {
    getTenantUsers();
    setLoading(false);
  }, []);
  useEffect(() => {
    clearList();
    loadStatusTransitionsAllowed();
    loadPoints();
  }, [fleetId]);
  useEffect(() => {
    if (qrInput?.current && !isMobile()) {
      qrInput.current.focus();
    }
  }, [qrInput]);
  if (loading || !tenant) {
    return (
      <div className={stylesLayout.page}>
        <Loading />
      </div>
    );
  }
  const scannedQrsHasDevice = scannedQrs && scannedQrs.filter((x) => x.device);
  // const stepsStore = getStepsStore(store);
  // const pendingStepsStore = (stepsStore.filter((x) => !x.completed));
  const {availableTransitions, preferredNewStatuses} = (() => {
    const fromStatuses = [
      ...new Set(
        scannedQrsHasDevice
          .map((x) => x.device?.status.toLowerCase())
          .filter((x) => !!x)
      ),
    ];
    const preferredTransitions = {
      live: ['motorist', 'in_transport_to_warehouse', 'in_transport_to_deploy'],
      disabled: ['live'],
      motorist: ['in_transport_to_warehouse'],
      missing_connection: ['motorist', 'in_transport_to_warehouse'],
      in_transport_to_deploy: ['live'],
      in_transport_to_warehouse: ['in_warehouse'],
      in_warehouse: ['in_transport_to_deploy'],
    };
    const uniqueStatus = fromStatuses.length === 1 ? fromStatuses[0] : null;
    let computedPreferredNewStatuses = null;
    if (uniqueStatus) {
      computedPreferredNewStatuses = preferredTransitions[uniqueStatus];
    }
    const r = fromStatuses.reduce((a, x) => {
      const availableTransitionsFromStatus = statusTransitionsAllowed[x] || [];
      if (a === null) {
        return availableTransitionsFromStatus;
      }
      return a.filter((v) => availableTransitionsFromStatus.includes(v));
    }, null);

    return {
      availableTransitions: r || [],
      preferredNewStatuses: computedPreferredNewStatuses,
    };
  })();
  let readyToChange = true;
  if (
    !selectedUserId ||
    (selectedUserId !== user.id && !selectedUserPassword)
  ) {
    readyToChange = false;
  }
  readyToChange = readyToChange && !saving && scannedQrsHasDevice.length > 0;
  const showUnlockButton = scannedQrs.every(
    (x) => x.device?.status === 'in_warehouse'
  );

  const getClosestPoint = (current, allPoints) => {
    if (!current.latitude || !current.longitude) {
      return null;
    }
    const lat = current.latitude;
    const lng = current.longitude;

    let dist = 10000000;
    let closestPoint = null;
    allPoints.map((p) => {
      const d = distance(lat, lng, p.lat, p.lng);
      if (d < dist) {
        closestPoint = {...p, distance: d};
        dist = d;
      }
      return null;
    });
    return closestPoint;
  };

  const closestPoint =
    points?.length > 0 && position?.latitude
      ? getClosestPoint(position, points)
      : null;
  let resumeMessage = '';
  if (showResume && scannedQrsHasDevice?.length > 0) {
    resumeMessage += `Ubicación: ${position?.latitude}, ${position?.longitude}`;
    if (closestPoint) {
      resumeMessage += ` (${closestPoint.name}, ${(
        closestPoint.distance * 1000
      ).toFixed(0)}m)`;
    }
    resumeMessage += `\n\n${scannedQrsHasDevice.length} Cambio${
      scannedQrsHasDevice.length !== 1 ? 's' : ''
    }\n\n`;
    const tags = scannedQrsHasDevice
      .map((x) => `${x.device.qr} - ${x.device.status_tr}`)
      .filter((x) => !!x);
    resumeMessage += tags.join('\n');
  }
  return (
    <>
      <div className={styles.cnt}>
        <div className={styles.input}>
          <div className={styles.inputInner}>
            <div>
              <Input
                value={qr}
                ref={qrInput}
                autoComplete={false}
                placeholder='QR o MAC...'
                onChange={(e) => setQr(e.target.value.toUpperCase())}
                onKeyDown={(e) => {
                  if (qr && e.key === 'Enter') {
                    const newQr = qr?.split(/[-/]+/).slice(-1)[0];
                    addToScannedQrs(newQr);
                    setQr('');
                  }
                }}
              />
            </div>
            <div className={styles.scan} onClick={startScanning}>
              <FontAwesomeIcon icon={faQrcode} />
            </div>
          </div>
          <Button
            type='primary'
            onClick={() => {
              if (qr) {
                const newQr = qr?.split(/[-/]+/).slice(-1)[0];
                addToScannedQrs(newQr);
                setQr('');
              }
            }}
          >
            Agregar
          </Button>
        </div>
        <div
          className={[styles.scanCamera, scanning ? styles.visible : ''].join(
            ' '
          )}
        >
          {scanning && (
            <>
              <div className={styles.closeBtn} onClick={endScanning}>
                &times;
              </div>
              <div className={styles.camera}>
                <QrReader
                  resolution={600}
                  constraints={{
                    aspectRatio: 1,
                    facingMode: 'environment',
                  }}
                  onError={(e) => console.log('QR ERROR', e)}
                  onLoad={({stream}) => {
                    console.log('ONLOAD');
                    streamRef.current = stream;
                  }}
                  onResult={(result) => {
                    if (result) {
                      const newQr = result?.text.split(/[-/]+/).slice(-1)[0];
                      addToScannedQrs(newQr);
                    }
                  }}
                />
              </div>
              {true && (
                <>
                  <div className={styles.torchButton}>
                    <FontAwesomeIcon
                      onClick={torchToggle}
                      icon={faLightbulb}
                      color={torchOn ? '#fc0' : '#999'}
                      style={{fontSize: 24}}
                    />
                  </div>
                  <div className={styles.torchMessage}>
                    <small>
                      * Si la linterna no enciende, puedes prenderla desde el
                      panel de notificaciones del teléfono.
                    </small>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.results}>
          <div className={styles.resultsTitle}>
            <div className={styles.line}>
              <div style={{float: 'right'}}>
                <Button danger onClick={clearList} size='small'>
                  Limpiar lista
                </Button>
              </div>
              <div>
                {position?.latitude ? (
                  <span>
                    <>
                      <FontAwesomeIcon
                        icon={faLocationArrow}
                        style={{color: '#090', marginRight: 10}}
                      />
                    </>
                  </span>
                ) : (
                  <span>
                    <>
                      <FontAwesomeIcon
                        icon={faLocationArrow}
                        style={{color: '#c30', marginRight: 5}}
                      />
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{color: '#c30', marginRight: 10}}
                      />
                    </>
                  </span>
                )}
                Esc. {scannedQrs && scannedQrs.length}
                {', '}
                Enc. {scannedQrsHasDevice.length}
              </div>
              {showResume && scannedQrsHasDevice?.length > 0 && (
                <div className={styles.resume}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{marginBottom: 5, fontWeight: 'bold'}}>
                        Ubicación: {position?.latitude}, {position?.longitude}
                        {closestPoint && (
                          <>
                            {' '}
                            ({closestPoint.name},{' '}
                            {(closestPoint.distance * 1000).toFixed(0)}
                            m)
                          </>
                        )}
                      </div>
                      <div style={{marginBottom: 10, fontWeight: 'bold'}}>
                        {scannedQrsHasDevice.length} Cambio
                        {scannedQrsHasDevice.length !== 1 ? 's' : ''}
                      </div>
                      <div>
                        {scannedQrsHasDevice.map((x) => (
                          <span
                            style={{
                              marginRight: 3,
                              marginBottom: 2,
                              borderRadius: 3,
                              padding: 4,
                              backgroundColor: '#eed',
                            }}
                          >
                            {x.device.qr} - (
                            {x.device.status_tr
                              .split(' ')
                              .map((w) => w[0])
                              .join('')
                              .toUpperCase()}
                            )
                          </span>
                        ))}
                      </div>
                    </div>
                    <div>
                      <CopyWithMessage
                        inline
                        value={resumeMessage}
                        messageCopied='Copiado!'
                        messageTime={2000}
                        style={{padding: 15}}
                      >
                        <FontAwesomeIcon icon={faCopy} style={{fontSize: 16}} />
                      </CopyWithMessage>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!isMobile() && (
              <div className={styles.byStatus}>
                <RowByStatus
                  size='small'
                  devices={scannedQrsHasDevice.map((x) => x.device)}
                  cols={isMobile() ? 4 : 6}
                />
              </div>
            )}
          </div>
          <div className={styles.resultsContent}>
            {[...scannedQrs].reverse().map((d) =>
              d.device ? (
                <div
                  className={[
                    styles.deviceItem,
                    styles[`status_${d.device.status}`],
                  ].join(' ')}
                >
                  <div>
                    {d.change_status && (
                      <div className={styles.change_status}>
                        {d.change_status === 'ok' ? (
                          <>
                            <FontAwesomeIcon icon={faCheck} color='#093' /> -
                            Cambiado con éxito
                          </>
                        ) : (
                          <FontAwesomeIcon icon={faTimes} color='#c30' />
                        )}
                        {d.change_status !== 'ok' && (
                          <>
                            {' '}
                            -
                            {d.change_message === 'no_permissions_for_role'
                              ? 'No tienes permiso para este cambio'
                              : d.change_message}
                          </>
                        )}
                      </div>
                    )}
                    <div className={styles.qr}>
                      {d.device.qr}{' '}
                      <Link to={`/device/${d.device.qr}`}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </Link>
                    </div>
                    {!d.device.fleet.cost.allow_without_iot &&
                    d.device.data.iot_type?.toLowerCase() === 'no' ? (
                      <div className={styles.damages}>
                        <FontAwesomeIcon
                          style={{color: '#c30'}}
                          icon={faExclamationTriangle}
                        />{' '}
                        Patin SIN IOT. No puede salir a calle
                      </div>
                    ) : null}
                    {d.device.has_active_damages ? (
                      <div className={styles.damages}>
                        <FontAwesomeIcon
                          style={{color: '#c30'}}
                          icon={faExclamationTriangle}
                        />{' '}
                        Patin con daños. No puede salir a calle
                      </div>
                    ) : null}
                    {d.device.data?.iot_type?.toLowerCase() !== 'no' && (
                      <div className={styles.firmware}>
                        {d.device.data?.iot_firmware_version !== 32001 && (
                          <>
                            <FontAwesomeIcon
                              style={{color: '#c30'}}
                              icon={faExclamationTriangle}
                            />{' '}
                          </>
                        )}
                        Firmware:{' '}
                        {d.device.data?.iot_firmware_version ||
                          '(Sin información)'}
                      </div>
                    )}
                    <div className={styles.fleet}>
                      {d.status === 'notinfleet' && (
                        <>
                          <FontAwesomeIcon
                            style={{color: '#c30'}}
                            icon={faExclamationTriangle}
                          />{' '}
                        </>
                      )}
                      {d.device.fleet?.name || 'Sin flota asignada'}
                      {d.status === 'notinfleet'
                        ? ' - No pertenece a una flota con acceso'
                        : ''}
                    </div>
                    <div>IOT: {d.device.data?.iot_type}</div>
                    {d.device.data?.iot_type?.toLowerCase() !== 'no' && (
                      <>
                        <div
                          className={styles.battery}
                          style={{fontWeight: 'bold'}}
                        >
                          {d.device.data?.batsco < 30 && (
                            <>
                              <FontAwesomeIcon
                                style={{color: '#c30'}}
                                icon={faExclamationTriangle}
                              />{' '}
                            </>
                          )}
                          {d.device.data?.batsco} % batería
                        </div>
                        <div
                          className={styles.last_conn}
                          style={{fontWeight: 'bold'}}
                        >
                          {d.device.data?.last_heartbeat_from_device_at &&
                            moment
                              .duration(
                                moment
                                  .utc()
                                  .diff(
                                    moment.utc(
                                      d.device.data
                                        ?.last_heartbeat_from_device_at
                                    )
                                  )
                              )
                              .asHours() >= 48 && (
                              <>
                                <FontAwesomeIcon
                                  style={{color: '#c30'}}
                                  icon={faExclamationTriangle}
                                />{' '}
                              </>
                            )}
                          Últ HB:{' '}
                          {d.device.data?.last_heartbeat_from_device_at
                            ? moment
                                .utc(
                                  d.device.data?.last_heartbeat_from_device_at
                                )
                                .fromNow()
                            : 'Sin info'}
                        </div>
                        <div
                          className={styles.last_conn}
                          style={{fontWeight: 'bold'}}
                        >
                          {d.device.data?.last_message_from_device_at &&
                            moment
                              .duration(
                                moment
                                  .utc()
                                  .diff(
                                    moment.utc(
                                      d.device.data?.last_message_from_device_at
                                    )
                                  )
                              )
                              .asHours() >= 48 && (
                              <>
                                <FontAwesomeIcon
                                  style={{color: '#c30'}}
                                  icon={faExclamationTriangle}
                                />{' '}
                              </>
                            )}
                          Últ Mensaje:{' '}
                          {d.device.data?.last_message_from_device_at
                            ? moment
                                .utc(d.device.data?.last_message_from_device_at)
                                .fromNow()
                            : 'Sin info'}
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{textAlign: 'right'}}>
                    {d.device.fleet_id && d.device.fleet_id !== 2 && (
                      <div className={styles.brand}>
                        {brands[d.device.fleet_id][d.device.brand] ??
                          d.device.brand}
                      </div>
                    )}
                    <div className={styles.status}>{d.device.status_tr}</div>
                    <div className={styles.updatedAt}>
                      Act: {moment.utc(d.device.updated_at).fromNow()}
                    </div>
                  </div>
                  <div style={{textAlign: 'right'}}>
                    <div
                      className={styles.deleteItemBtn}
                      onClick={() => {
                        removeScannedQr(d.q);
                      }}
                    >
                      &times;
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={[
                    styles.deviceItem,
                    d.status === 'loading' ? styles.loading : styles.notFound,
                  ].join(' ')}
                >
                  <div>
                    <div className={styles.qr}>{d.q}</div>
                    <div className={styles.fleet}>
                      {d.status === 'loading' ? 'Buscando...' : 'No encontrado'}
                    </div>
                  </div>
                  <div />
                  <div style={{textAlign: 'right'}}>
                    <div
                      className={styles.deleteItemBtn}
                      onClick={() => {
                        removeScannedQr(d.q);
                      }}
                    >
                      &times;
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {!isMobile() && (
            <div className={styles.setLocation}>
              {!!changeLocationOnUpdate}
              <Checkbox
                defaultChecked={!!changeLocationOnUpdate}
                onChange={(e) => {
                  setChangeLocationOnUpdate(e.target.checked);
                }}
              >
                Poner en ubicación del cambio de estado
              </Checkbox>
            </div>
          )}
          {user.roles
            .map((x) => x.role)
            .filter((x) => ['react', 'admin', 'warehouse_operator'].includes(x))
            .length > 0 && (
            <div className={styles.user}>
              <div>
                Asignar a:
                <Select
                  showSearch
                  placeholder='Elige un responsable'
                  filterOption={(inputValue, option) =>
                    option.label &&
                    option.label
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  style={{marginLeft: 0, width: 300}}
                  value={selectedUserId}
                  allowClear
                  onChange={(v) => {
                    setSelectedUserId(v);
                    setSelectedUserPassword('');
                  }}
                  options={
                    users &&
                    users
                      .filter((x) => !nonValidUserEmails.includes(x.email))
                      .map((u) => ({
                        value: u.id,
                        label: `${u.name} - ${u.email}`,
                      }))
                  }
                />
              </div>
              {selectedUserId !== user.id && (
                <div>
                  <Input.Password
                    autoComplete='new-password'
                    key={selectedUserId}
                    defaultValue={selectedUserPassword}
                    placeholder='Contraseña del usuario...'
                    onChange={(e) => setSelectedUserPassword(e.target.value)}
                  />
                </div>
              )}
            </div>
          )}
          <div className={styles.buttons}>
            {saving ? (
              <div style={{marginTop: 15}}>
                <Loading />
              </div>
            ) : (
              <>
                {readyToChange && showUnlockButton && (
                  <>
                    <Button
                      size='large'
                      danger
                      disabled={!readyToChange}
                      onClick={() => sendDevicesCommand('1,0')}
                    >
                      <FontAwesomeIcon
                        icon={faUnlockAlt}
                        style={{marginRight: 5}}
                      />
                      Desbloquear
                    </Button>
                    <Button
                      size='large'
                      danger
                      disabled={!readyToChange}
                      onClick={() => sendDevicesCommand('1,1')}
                    >
                      <FontAwesomeIcon
                        icon={faUnlockAlt}
                        style={{marginRight: 5}}
                      />
                      Bloquear
                    </Button>
                  </>
                )}
                {preferredNewStatuses &&
                  preferredNewStatuses.map(
                    (x) =>
                      availableTransitions.includes(x) && (
                        <Button
                          size='large'
                          disabled={!readyToChange}
                          type='primary'
                          onClick={() => moveDevicesToStatus(x)}
                        >
                          {statusTranslations[x] || x}
                        </Button>
                      )
                  )}
                {[
                  'in_warehouse',
                  'in_transport_to_deploy',
                  'live',
                  'in_transport_to_warehouse',
                  'in_transport_to_test',
                  'disabled',
                  'damaged',
                  'maintenance',
                  'stolen',
                  'in_transport_to_grin4u_user',
                  'user_grin4u',
                  'available',
                  'non_available',
                  'scheduled_to_pickup',
                  'customer',
                  'scrap',
                  'in_transport_to_user',
                ]
                  .filter(
                    (x) =>
                      !preferredNewStatuses || !preferredNewStatuses.includes(x)
                  )
                  .map(
                    (x) =>
                      availableTransitions.includes(x) && (
                        <Button
                          size='large'
                          disabled={!readyToChange}
                          onClick={() => moveDevicesToStatus(x)}
                        >
                          {statusTranslations[x] || x}
                        </Button>
                      )
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FleetManagementModule.propTypes = {
  user: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  fleets: PropTypes.array.isRequired,
  fleetId: PropTypes.number.isRequired,
};

export default FleetManagementModule;
