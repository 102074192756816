import React from 'react';
import PropTypes from 'prop-types';

import {Table, notification} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faUpload} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import UploaderFile from '../UploaderFile';
import * as subscriptionsService from '../../services/subscriptions';

import {documentTypes} from '../../helpers/params';
import stylesLayout from '../../common/layout.module.scss';
import {humanFileSize} from '../../helpers/utils';

const SubscriptionDocumentsTable = ({
  documents,
  subscriptionId,
  onChangeDocuments,
}) => {
  const columns = [
    {
      title: 'Fecha de carga',
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      width: 150,
      render: (v) =>
        v ? moment.utc(v).local().format('YYYY-MM-DD HH:mm') : '-',
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (v) => (v ? documentTypes[v] || v : 'Documento'),
    },
    {
      title: '',
      dataIndex: 'private_url',
      align: 'center',
      key: 'private_url',
      render: (v, r) =>
        ['image/jpeg', 'image/png'].includes(r.mimetype) ? (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <a href={v} target='_blank' rel='noreferrer'>
            <img style={{height: 150}} src={v} alt='' />
          </a>
        ) : null,
    },
    {
      title: 'Link',
      dataIndex: 'private_url',
      align: 'center',
      key: 'private_url',
      render: (v) => (
        <a href={v} target='_blank' rel='noreferrer'>
          {v}
        </a>
      ),
    },
  ].filter((x) => !!x);

  return (
    <div>
      <Table
        size='small'
        pagination={{hideOnSinglePage: true, defaultPageSize: 20}}
        locale={{emptyText: 'Sin suscripciones'}}
        columns={columns}
        dataSource={
          documents
            ? documents.map((d) => ({
                key: d.id,
                ...d,
              }))
            : []
        }
      />
      <div style={{marginTop: 20}}>
        <UploaderFile
          onUpload={async ({file}) => {
            notification.info({
              message: 'Agregando documento...',
              placement: 'top',
              key: 'result',
              duration: 0,
            });
            const rsp = await subscriptionsService.addDocument(
              subscriptionId,
              file
            );
            if (rsp?.status) {
              onChangeDocuments(rsp.data);
              notification.success({
                message: 'Documento agregado.',
                placement: 'top',
                key: 'result',
              });
            } else {
              notification.error({
                message: 'Error al agregar documento',
                placement: 'top',
                key: 'result',
              });
            }
            console.log(file);
          }}
          render={({uploading, progress}) => (
            <>
              {!uploading && (
                <div className={stylesLayout.uploader}>
                  <div className={stylesLayout.uploaderInner}>
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{marginRight: 10}}
                    />{' '}
                    Haz click o arrastra un nuevo documento aquí
                  </div>
                </div>
              )}
              {uploading &&
                progress &&
                progress.map((p) => (
                  <div>
                    <div style={{width: `${p.percent} % `}} />
                    <div>
                      {p.percent >= 100 ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color='#093'
                          style={{marginRight: 10, color: '#093'}}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faUpload}
                          color='#999'
                          style={{marginRight: 10, color: '#999'}}
                        />
                      )}
                      <div>
                        <span
                          style={{
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: '80%',
                          }}
                        >
                          {p.name}
                        </span>
                        {p.percent >= 100 && <span> - Guardando...</span>}
                      </div>
                      <div>
                        {humanFileSize((p.size * p.percent) / 100)} de{' '}
                        {humanFileSize(p.size)}
                      </div>
                      <div>{parseInt(p.percent, 10)}%</div>
                    </div>
                  </div>
                ))}
            </>
          )}
        />
      </div>
    </div>
  );
};

SubscriptionDocumentsTable.defaultProps = {
  documents: [],
};
SubscriptionDocumentsTable.propTypes = {
  documents: PropTypes.array,
  subscriptionId: PropTypes.number.isRequired,
  onChangeDocuments: PropTypes.func.isRequired,
};

export default SubscriptionDocumentsTable;
