/* eslint-disable no-nested-ternary */
import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withGoogleMap, GoogleMap, Polygon, Polyline} from 'react-google-maps';
import {DrawingManager} from 'react-google-maps/lib/components/drawing/DrawingManager';

import moment from 'moment';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import ScooterMarker from '../../assets/scootersMap';
import InternalTransportMarker from '../../assets/internalTransport';

import {aproxMinsToHoursDays} from '../../helpers/utils';
import {createEvent} from '../../services/events';

export const getScooterMarker = (
  status,
  type = 'scooter',
  alert = false,
  marker = {}
) => {
  if (type === 'internal_transport') {
    if (
      marker.qr?.startsWith('MOT') ||
      marker.qr?.startsWith('CLMOT') ||
      marker.qr?.startsWith('PEMOT')
    ) {
      return InternalTransportMarker.moto;
    }
    if (
      marker.qr?.startsWith('VAN') ||
      marker.qr?.startsWith('CLVAN') ||
      marker.qr?.startsWith('PEVAN')
    ) {
      return InternalTransportMarker.van;
    }
    return InternalTransportMarker.moto;
  }
  switch (status) {
    case 'live':
      return ScooterMarker.greenlight;
    case 'reserved':
      return ScooterMarker.yellow;
    case 'in_ride':
      if (alert) {
        return ScooterMarker.rideRed;
      }
      return ScooterMarker.rideGreen;

    case 'in_transport_to_deploy':
    case 'in_transport_to_grin4u_user':
      return ScooterMarker.blue;
    case 'in_transport_to_warehouse':
      return ScooterMarker.purple;
    case 'in_transport_to_test':
    case 'motorist':
      return ScooterMarker.pink;
    case 'in_warehouse':
      return ScooterMarker.orange;
    case 'stolen_suspect':
      return ScooterMarker.stolenPurple;
    case 'damaged':
      return ScooterMarker.red;
    case 'maintenance':
    case 'in_warehouse_need_test':
      return ScooterMarker.coffee;
    case 'stolen':
      return ScooterMarker.stolenBlack;
    default:
      return ScooterMarker.gray;
  }
};
const CustomMarker = (props) => {
  const {label, icon} = props;
  const {label: labelX, ...markerProps} = props;
  return (
    <>
      <MarkerWithLabel
        {...markerProps}
        icon={icon}
        onClick={() => {
          props.onSelectMarker();
        }}
      >
        <div>{label}</div>
      </MarkerWithLabel>
    </>
  );
};
CustomMarker.defaultProps = {
  label: <></>,
  infoWindow: <></>,
  icon: null,
  marker: null,
  onSelectMarker: () => {},
};
CustomMarker.propTypes = {
  position: PropTypes.object.isRequired,
  marker: PropTypes.object,
  icon: PropTypes.object,
  infoWindow: PropTypes.object,
  label: PropTypes.object,
  onSelectMarker: PropTypes.func,
};

const EditablePolygon = (props) => {
  const polygonRef = useRef(null);
  const onPolygonMouseUp = () => {
    const path = polygonRef.current
      .getPath()
      .getArray()
      .map((x) => ({latitude: x.lat(), longitude: x.lng()}));

    if (props.onPolygonPathChange) {
      props.onPolygonPathChange(path);
    }
  };

  return <Polygon ref={polygonRef} {...props} onMouseUp={onPolygonMouseUp} />;
};

EditablePolygon.defaultProps = {
  onPolygonPathChange: () => {},
};
EditablePolygon.propTypes = {
  onPolygonPathChange: PropTypes.func,
};

export const MapComponent = withGoogleMap((props) => {
  const mapRef = useRef();
  const [mapHover, setMapHover] = useState(null);

  useEffect(() => {
    (async () => {
      const url2 = `${document.location.pathname}${document.location.search}${document.location.hash}`;
      const payload = {
        event: 'rental:create_map',
        item_type: 'system',
        user_id: 0,
        item_id: 0,
        lat: props.defaultCenter?.lat,
        lng: props.defaultCenter?.lng,
        data: {
          fleet_id: props.fleetId || 0,
          url: url2,
        },
      };
      await createEvent(payload);
    })();
  }, []);

  useEffect(() => {
    if (!props.panTo) {
      return;
    }
    if (mapRef && mapRef.current && mapRef.current.getBounds()) {
      if (
        mapRef.current.getBounds().getCenter().lat() !== props.panTo.latitude &&
        mapRef.current.getBounds().getCenter().lng() !== props.panTo.longitude
      ) {
        mapRef.current.panTo(props.panTo);
      }
    }
  }, [props.panTo]);

  const markerSize = (mapRef.current?.getZoom() || props.zoom) < 18 ? 33 : 44;
  return (
    <>
      <GoogleMap
        zoom={props.zoom}
        defaultZoom={props.zoom}
        ref={mapRef}
        onBoundsChanged={() => {
          if (
            props.onBoundsChanged &&
            mapRef &&
            mapRef.current &&
            mapRef.current.getBounds()
          ) {
            if (
              mapRef.current.getBounds().getCenter().lat() !==
                props.defaultCenter.lat &&
              mapRef.current.getBounds().getCenter().lng() !==
                props.defaultCenter.lng
            ) {
              props.onBoundsChanged(
                {
                  lat: mapRef.current.getBounds().getSouthWest().lat(),
                  lng: mapRef.current.getBounds().getSouthWest().lng(),
                },
                {
                  lat: mapRef.current.getBounds().getNorthEast().lat(),
                  lng: mapRef.current.getBounds().getNorthEast().lng(),
                },
                {
                  lat: mapRef.current.getBounds().getCenter().lat(),
                  lng: mapRef.current.getBounds().getCenter().lng(),
                }
              );
            }
          }
        }}
        options={{
          disableDefaultUI: false,
          zoomControl: true,
          fullScreenControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          maxZoom: props.maxZoom || props.zoom + 20,
          minZoom: props.minZoom || props.zoom - 2,
        }}
        defaultCenter={props.defaultCenter || props.center}
      >
        {props.drawingControlEnabled && (
          <DrawingManager
            defaultDrawingMode={null}
            onOverlayComplete={(o) => {
              if (props.onOverlayComplete) {
                props.onOverlayComplete(o.type, o.overlay);
              }
              o.overlay.setMap(null);
            }}
            defaultOptions={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  window.google.maps.drawing.OverlayType.RECTANGLE,
                ],
              },
              rectangleOptions: {
                fillColor: '#999999',
                fillOpacity: 0.5,
                strokeWeight: 1,
                clickable: false,
                editable: false,
                zIndex: 5,
              },
            }}
          />
        )}
        {props.currentLocation &&
          props.currentLocation.latitude &&
          props.currentLocation.longitude && (
            <CustomMarker
              key={`current-location-${mapRef.current?.getZoom()}`}
              position={{
                lat: props.currentLocation.latitude,
                lng: props.currentLocation.longitude,
              }}
              icon={
                props.currentLocation?.icon
                  ? {
                      scaledSize: new window.google.maps.Size(
                        markerSize,
                        markerSize
                      ),
                      url: props.currentLocation.icon,
                      anchor: new window.google.maps.Point(
                        markerSize / 2,
                        markerSize
                      ),
                    }
                  : null
              }
              color='#444'
              marker={null}
              label={<>Tu ubicación</>}
              labelAnchor={{x: 0, y: -5}}
              labelStyle={{
                backgroundColor: '#fff',
                color: '#444',
                padding: '3px',
                borderRadius: '3px',
                border: '1px solid #eee',
              }}
              infoWindow={<></>}
            />
          )}

        {props.markers &&
          props.markers
            .filter((x) => x.type === 'internal_transport')
            .map((marker) => (
              <CustomMarker
                key={`i-${marker.id}-${mapRef.current?.getZoom()}`}
                position={{
                  lat: marker.latitude || marker.lat,
                  lng: marker.longitude || marker.lng,
                }}
                onSelectMarker={() => {
                  if (props.onSelectMarker) {
                    props.onSelectMarker(marker);
                  }
                }}
                icon={{
                  scaledSize:
                    marker.iconScale ||
                    new window.google.maps.Size(markerSize, markerSize),
                  url:
                    marker.icon ||
                    getScooterMarker(
                      marker.status,
                      marker.type,
                      marker.data?.out_of_area,
                      marker
                    ),
                  anchor: new window.google.maps.Point(
                    markerSize / 2,
                    markerSize
                  ),
                }}
                color='#ffcc00'
                marker={marker}
                label={
                  marker.label || (
                    <div style={{textAlign: 'center'}}>
                      {marker.qr}
                      <br />
                      {marker.data?.owner && (
                        <div>{marker.data.owner.name}</div>
                      )}
                      {marker.data.batiot && (
                        <>
                          Bat IOT: {marker.data.batiot}
                          %
                          <br />
                        </>
                      )}
                      {marker.data.mins_since_last_connection && (
                        <>
                          Últ. con.:{' '}
                          {aproxMinsToHoursDays(
                            marker.data.mins_since_last_connection
                          )}
                          <br />
                        </>
                      )}
                      {marker.data?.coderr > 0 && (
                        <span>Err: {marker.data?.coderr}</span>
                      )}
                    </div>
                  )
                }
                infoWindow={
                  <div>
                    <div>QR: {marker.qr}</div>
                    <div>Status: {marker.status_tr}</div>
                    <div>
                      Geo Updated:{' '}
                      {marker.geo_updated_at
                        ? moment.utc(marker.geo_updated_at).fromNow()
                        : 'Unknown'}
                    </div>
                  </div>
                }
                labelAnchor={{x: 15, y: -5}}
                labelStyle={{
                  backgroundColor: props.selectedMarkers?.includes(marker.id)
                    ? '#06c'
                    : 'white',
                  color: props.selectedMarkers?.includes(marker.id)
                    ? '#fff'
                    : '444',
                  padding: '3px',
                  borderRadius: '3px',
                  border: '1px solid #eee',
                }}
              />
            ))}
        <MarkerClusterer
          averageCenter
          onClick={() => {}}
          maximumClusterSize={props.maximumClusterSize || 5}
          minimumClusterSize={props.minimumClusterSize || 2}
          maxZoom={19}
          gridSize={props.clusterGridSize || 40}
        >
          {props.lines &&
            props.lines.map((line) => <Polyline path={line.path} />)}
          {props.polygons &&
            props.polygons.map((polygon) => (
              <EditablePolygon
                key={polygon.id}
                editable={polygon.editable}
                draggable={polygon.editable}
                defaultPath={polygon.points.map((p) => ({
                  lat: p.latitude,
                  lng: p.longitude,
                }))}
                onPolygonPathChange={(path) =>
                  props.onPolygonPathChange &&
                  props.onPolygonPathChange(polygon.id, path)
                }
                onClick={() =>
                  props.onClickPolygon && props.onClickPolygon(polygon.id)
                }
                onRightClick={() =>
                  props.onPolygonRightClick &&
                  props.onPolygonRightClick(polygon)
                }
                options={{
                  fillColor: polygon.color || '#ffcc00',
                  strokeColor: polygon.color || '#ffcc00',
                  strokeWeight: 2,
                  zIndex:
                    polygon.type === 'MICROZONE' ||
                    polygon.type === 'MICROZONE_WAREHOUSE'
                      ? 3
                      : 1,
                }}
              />
            ))}
          {props.blocks &&
            props.blocks.map((block, index) => (
              <Polygon
                key={block.id || index}
                onClick={() => {
                  if (block.label) {
                    setMapHover(block.label);
                  }
                }}
                defaultPath={[
                  {lat: block.lat0, lng: block.lng0},
                  {lat: block.lat0, lng: block.lng1},
                  {lat: block.lat1, lng: block.lng1},
                  {lat: block.lat1, lng: block.lng0},
                ]}
                options={{
                  fillColor: block.color || '#ffcc00',
                  strokeColor: block.color || '#ffcc00',
                  fillOpacity: block.opacity || 1,
                  strokeOpacity: 0.25,
                  strokeWeight: block.strokeWeight || 1,
                  zIndex: 5,
                }}
              />
            ))}
          {props.markers &&
            props.markers
              .filter((x) => x.type !== 'internal_transport')
              .map((marker) => (
                <CustomMarker
                  key={`d-${marker.id}-${mapRef.current?.getZoom()}`}
                  position={{
                    lat: marker.latitude || marker.lat,
                    lng: marker.longitude || marker.lng,
                  }}
                  onSelectMarker={() => {
                    if (props.onSelectMarker) {
                      props.onSelectMarker(marker);
                    }
                  }}
                  zIndex={marker.zIndex || 1000}
                  icon={{
                    scaledSize:
                      marker.iconScale ||
                      new window.google.maps.Size(markerSize, markerSize),

                    url:
                      marker.icon ||
                      getScooterMarker(
                        marker.status,
                        marker.type,
                        marker.data?.out_of_area,
                        marker
                      ),
                    anchor:
                      marker.anchor ||
                      new window.google.maps.Point(markerSize / 2, markerSize),
                  }}
                  color='#ffcc00'
                  marker={marker}
                  label={
                    marker.label || (
                      <div style={{textAlign: 'center'}}>
                        {marker.qr}
                        {marker.type !== 'internal_transport' &&
                          marker.type !== 'gps' && (
                            <>
                              {' '}
                              <br /> {marker.status_tr}
                            </>
                          )}
                        {marker.out_of_area && ' - Fuera de zona'}
                        <br />
                        {marker.type !== 'internal_transport' &&
                        marker.data.batsco ? (
                          <>
                            Bat: {marker.data.batsco}
                            %
                            <br />
                          </>
                        ) : marker.type !== 'gps' ? (
                          'Sin batería'
                        ) : (
                          ''
                        )}
                        {marker.data.batiot && (
                          <>
                            Bat IOT: {marker.data.batiot}
                            %
                            <br />
                          </>
                        )}
                        {marker.data.mins_since_last_connection && (
                          <>
                            Últ. con.:{' '}
                            {aproxMinsToHoursDays(
                              marker.data.mins_since_last_connection
                            )}
                            <br />
                          </>
                        )}
                        {marker.data?.coderr > 0 && (
                          <span>Err: {marker.data?.coderr}</span>
                        )}
                      </div>
                    )
                  }
                  infoWindow={
                    <div>
                      <div>QR: {marker.qr}</div>
                      <div>Status: {marker.status_tr}</div>
                      <div>
                        Geo Updated:{' '}
                        {marker.geo_updated_at
                          ? moment.utc(marker.geo_updated_at).fromNow()
                          : 'Unknown'}
                      </div>
                    </div>
                  }
                  labelAnchor={{x: 15, y: -5}}
                  labelStyle={{
                    backgroundColor: props.selectedMarkers?.includes(marker.id)
                      ? '#06c'
                      : 'white',
                    color: props.selectedMarkers?.includes(marker.id)
                      ? '#fff'
                      : '444',
                    padding: '3px',
                    borderRadius: '3px',
                    border: '1px solid #eee',
                  }}
                />
              ))}
        </MarkerClusterer>
        {props.heatmap && (
          <HeatmapLayer
            data={props.heatmap.data}
            options={{radius: 20, ...props.heatmap.options}}
          />
        )}
      </GoogleMap>
      {mapHover && (
        <div
          style={{
            position: 'absolute',
            bottom: 60,
            left: 3,
            background: '#fff',
            borderRadius: 3,
            border: '1px solid #ccc',
            padding: '3px 5px',
            zIndex: 2,
          }}
        >
          {mapHover}
        </div>
      )}
    </>
  );
});

export default {MapComponent, getScooterMarker};
