/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  withGoogleMap,
  GoogleMap,
  Polygon,
  Circle,
  Polyline,
} from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

import {Button, Slider} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faForward,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {interpolateColor, convertToRGB} from '../../helpers/utils';
import {createEvent} from '../../services/events';

import styles from './index.module.scss';

const CustomMarker = (props) => {
  const {label} = props;
  const {label: labelX, ...markerProps} = props;
  return (
    <>
      <MarkerWithLabel
        {...markerProps}
        onClick={() => {
          props.onSelectMarker();
        }}
      >
        {label && label !== '' && <div>{label}</div>}
      </MarkerWithLabel>
    </>
  );
};
CustomMarker.defaultProps = {
  label: <></>,
  onSelectMarker: () => {},
};
CustomMarker.propTypes = {
  marker: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  infoWindow: PropTypes.object.isRequired,
  label: PropTypes.object,
  onSelectMarker: PropTypes.func,
};

const EditablePolygon = (props) => {
  const polygonRef = useRef(null);
  const onPolygonMouseUp = () => {
    const path = polygonRef.current
      .getPath()
      .getArray()
      .map((x) => ({latitude: x.lat(), longitude: x.lng()}));

    if (props.onPolygonPathChange) {
      props.onPolygonPathChange(path);
    }
  };

  return <Polygon ref={polygonRef} {...props} onMouseUp={onPolygonMouseUp} />;
};

EditablePolygon.defaultProps = {
  onPolygonPathChange: () => {},
};
EditablePolygon.propTypes = {
  onPolygonPathChange: PropTypes.func,
};

export const SimpleMapComponent = withGoogleMap((props) => {
  const [currentTs, setCurrentTs] = useState();
  const [minTs, setMinTs] = useState();
  const [stepTs, setStepTs] = useState();
  const [maxTs, setMaxTs] = useState();
  const [playingTs, setPlayingTs] = useState();

  const mapRef = useRef();
  const [mapHover, setMapHover] = useState(null);

  const {blocks} = props;

  useEffect(() => {
    if (props.markers) {
      const sMin = Math.min(...props.markers.map((x) => x.ts));
      const sMax = Math.max(...props.markers.map((x) => x.ts));
      setMinTs(sMin);
      setMaxTs(sMax);
      setStepTs((sMax - sMin) / 100);
      setCurrentTs(sMin);
    }
  }, [props.markers]);

  useEffect(() => {
    if (!props.panTo) {
      return;
    }
    if (mapRef && mapRef.current && mapRef.current.getBounds()) {
      if (
        mapRef.current.getBounds().getCenter().lat() !== props.panTo.latitude &&
        mapRef.current.getBounds().getCenter().lng() !== props.panTo.longitude
      ) {
        mapRef.current.panTo(props.panTo);
      }
    }
  }, [props.panTo]);

  useEffect(() => {
    let pid = null;
    if (playingTs) {
      pid = window.setInterval(() => {
        setCurrentTs((s) => {
          const newTs = s + (maxTs - minTs) / (10000 / 50);
          if (newTs >= maxTs) {
            setPlayingTs(false);
            return maxTs;
          }
          return newTs;
        });
      }, 50);
    }
    return () => {
      if (pid) {
        window.clearInterval(pid);
      }
    };
  }, [playingTs]);

  useEffect(() => {
    (async () => {
      const url2 = `${document.location.pathname}${document.location.search}${document.location.hash}`;
      const payload = {
        event: 'rental:create_map',
        item_type: 'system',
        user_id: 0,
        item_id: 0,
        lat: props.defaultCenter?.lat,
        lng: props.defaultCenter?.lng,
        data: {
          fleet_id: props.fleetId || 0,
          url: url2,
        },
      };
      await createEvent(payload);
    })();
  }, []);

  const tooglePlayingTs = () => {
    if (playingTs === false && currentTs >= maxTs) {
      setCurrentTs(minTs);
    }
    setPlayingTs((s) => !s);
  };

  let blocksHeatmap = {};
  if (props.blockHeatmap) {
    const {
      delta = 10,
      colorRange = ['cc3366', 'cc3366'],
      weightFactor = 1,
    } = props.blockHeatmap.options;
    props.blockHeatmap.data.map((x) => {
      const latBucket = Math.floor(x.location.lat() / delta) * delta;
      const lngBucket = Math.floor(x.location.lng() / delta) * delta;
      if (!blocksHeatmap[`${latBucket},${lngBucket}`]) {
        blocksHeatmap[`${latBucket},${lngBucket}`] = {
          id: `${latBucket},${lngBucket}`,
          qty: 0,
          weight: 0,
          items: [],
          color: x.color || null,
          opacity: x.opacity || null,
          box: {
            lat1: latBucket,
            lng1: lngBucket,
            lat2: latBucket + delta,
            lng2: lngBucket + delta,
            latC: latBucket + delta / 2,
            lngC: lngBucket + delta / 2,
          },
        };
      }
      blocksHeatmap[`${latBucket},${lngBucket}`].qty += 1;
      blocksHeatmap[`${latBucket},${lngBucket}`].weight += x.weight;
      blocksHeatmap[`${latBucket},${lngBucket}`].items.push(x);
      return null;
    });
    blocksHeatmap = Object.values(blocksHeatmap);
    const maxQty = Math.max(...blocksHeatmap.map((x) => x.qty));
    blocksHeatmap = blocksHeatmap.map((x) => ({
      ...x,
      label: `${x.qty} elemento${x.qty !== 1 ? 's' : ''} (${x.box.latC},${x.box.lngC}) (${x.weight})`,
      color:
        x.color ||
        `rgb(${interpolateColor(convertToRGB(colorRange[0]), convertToRGB(colorRange[1]), x.qty / maxQty).join(',')})`,
      opacity: x.opacity || (x.qty / maxQty) * 0.25 + 0.25,
      strokeOpacity: 5,
      radius: (delta / 2) * (x.weight || x.qty) * weightFactor * 2 * 111000,
    }));
  }
  const onChangeBoundsOrZoom = () => {
    if (
      props.onChangeBoundsZoom &&
      mapRef &&
      mapRef.current &&
      mapRef.current.getBounds()
    ) {
      if (
        mapRef.current.getBounds().getCenter().lat() !==
          props.defaultCenter.lat &&
        mapRef.current.getBounds().getCenter().lng() !== props.defaultCenter.lng
      ) {
        props.onChangeBoundsZoom(
          {
            lat: mapRef.current.getBounds().getSouthWest().lat(),
            lng: mapRef.current.getBounds().getSouthWest().lng(),
          },
          {
            lat: mapRef.current.getBounds().getNorthEast().lat(),
            lng: mapRef.current.getBounds().getNorthEast().lng(),
          },
          {
            lat: mapRef.current.getBounds().getCenter().lat(),
            lng: mapRef.current.getBounds().getCenter().lng(),
          },
          mapRef.current.getZoom()
        );
      }
    }
  };
  const {timelineOptions = {}} = props;
  return (
    <div style={{position: 'relative'}}>
      {props.withTimeline && (
        <div className={styles.timeline}>
          <div
            className={styles.elements}
            onClick={(e) => {
              const x = e.nativeEvent.offsetX / e.currentTarget.clientWidth;
              setCurrentTs(minTs + (maxTs - minTs) * x);
            }}
          >
            <div
              style={{
                position: 'absolute',
                background: '#fc0',
                left: `${((currentTs - minTs) / (maxTs - minTs)) * 100}%`,
                width: `${((stepTs * 1) / (maxTs - minTs)) * 100}%`,
                top: 0,
                bottom: 0,
                zIndex: 8,
              }}
            />
            {props.markers.map((x) => (
              <div
                style={{
                  position: 'absolute',
                  background: x.timeline?.mark?.color || '#666',
                  left: `${((x.ts - minTs) / (maxTs - minTs)) * 100}%`,
                  width: 0.25,
                  top: x.timeline?.mark?.top || '20%',
                  bottom: x.timeline?.mark?.bottom || '20%',
                  zIndex: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentTs(x.ts);
                }}
              />
            ))}
          </div>
          <div className={styles.controls}>
            <Button onClick={tooglePlayingTs}>
              <FontAwesomeIcon icon={playingTs ? faPause : faPlay} />
            </Button>
            <Button
              onClick={() => {
                const nextEvents = [...props.markers]
                  .sort((a, b) => (a.ts > b.ts ? -1 : 1))
                  .filter((x) => x.ts < currentTs - 10);
                const nextEvent = nextEvents?.length > 0 && nextEvents[0];
                if (nextEvent) {
                  setCurrentTs(nextEvent.ts);
                }
              }}
            >
              <FontAwesomeIcon icon={faBackward} />
            </Button>
            <Button
              onClick={() => {
                const nextEvents = [...props.markers]
                  .sort((a, b) => (a.ts > b.ts ? 1 : -1))
                  .filter((x) => x.ts > currentTs + 10);
                const nextEvent = nextEvents?.length > 0 && nextEvents[0];
                if (nextEvent) {
                  setCurrentTs(nextEvent.ts);
                }
              }}
            >
              <FontAwesomeIcon icon={faForward} />
            </Button>
            <div className={styles.currentTs}>
              {moment
                .utc(currentTs * 1000)
                .local()
                .format(timelineOptions.format || 'DD/MM HH:mm')}
            </div>
            <div className={styles.slider}>
              <Slider
                min={minTs}
                tooltip={{
                  formatter: (value) =>
                    `${moment
                      .utc(value * 1000)
                      .local()
                      .format(timelineOptions.format || 'DD/MM HH:mm')}`,
                }}
                max={maxTs}
                onChange={(v) => setCurrentTs(v)}
                value={currentTs}
              />
            </div>
          </div>
        </div>
      )}

      <GoogleMap
        zoom={props.zoom}
        onClick={props.onClick ? props.onClick : undefined}
        defaultZoom={props.zoom}
        ref={mapRef}
        onZoomChanged={onChangeBoundsOrZoom}
        onDragEnd={onChangeBoundsOrZoom}
        onBoundsChanged={() => {
          if (
            props.onBoundsChanged &&
            mapRef &&
            mapRef.current &&
            mapRef.current.getBounds()
          ) {
            if (
              mapRef.current.getBounds().getCenter().lat() !==
                props.defaultCenter.lat &&
              mapRef.current.getBounds().getCenter().lng() !==
                props.defaultCenter.lng
            ) {
              props.onBoundsChanged(
                {
                  lat: mapRef.current.getBounds().getSouthWest().lat(),
                  lng: mapRef.current.getBounds().getSouthWest().lng(),
                },
                {
                  lat: mapRef.current.getBounds().getNorthEast().lat(),
                  lng: mapRef.current.getBounds().getNorthEast().lng(),
                },
                {
                  lat: mapRef.current.getBounds().getCenter().lat(),
                  lng: mapRef.current.getBounds().getCenter().lng(),
                }
              );
            }
          }
        }}
        options={{
          disableDefaultUI: false,
          fullScreenControl: false,
          maxZoom: props.maxZoom || props.zoom + 20,
          minZoom: props.minZoom || props.zoom - 2,
          ...props.googleMapOptions,
        }}
        defaultCenter={props.defaultCenter || props.center}
      >
        <MarkerClusterer
          averageCenter
          onClick={() => {}}
          enableRetinaIcons
          minimumClusterSize={props.minimumClusterSize || 2}
          maxZoom={19}
          gridSize={60}
        >
          {props.lines &&
            props.lines.map((line, index) => (
              <Polyline path={line.path} key={line.id || index} />
            ))}
          {blocks &&
            blocks.map((block, index) => (
              <Polygon
                key={block.id || index}
                onClick={() => {
                  if (block.label) {
                    setMapHover(block.label);
                  }
                }}
                defaultPath={[
                  {lat: block.lat0, lng: block.lng0},
                  {lat: block.lat0, lng: block.lng1},
                  {lat: block.lat1, lng: block.lng1},
                  {lat: block.lat1, lng: block.lng0},
                ]}
                options={{
                  fillColor: block.color || '#ffcc00',
                  strokeColor: block.color || '#ffcc00',
                  fillOpacity: block.opacity || 1,
                  strokeOpacity: 0.25,
                  strokeWeight: block.strokeWeight || 1,
                }}
              />
            ))}
          {props.polygons &&
            props.polygons.map((polygon) => (
              <>
                <EditablePolygon
                  key={polygon.id}
                  editable={polygon.editable}
                  draggable={polygon.editable}
                  defaultPath={polygon.points.map((p) => ({
                    lat: p.latitude,
                    lng: p.longitude,
                  }))}
                  onPolygonPathChange={(path) =>
                    props.onPolygonPathChange &&
                    props.onPolygonPathChange(polygon.id, path)
                  }
                  onClick={() =>
                    props.onClickPolygon && props.onClickPolygon(polygon.id)
                  }
                  options={{
                    fillColor: polygon.color || '#ffcc00',
                    strokeColor: polygon.color || '#ffcc00',
                    strokeWeight: 2,
                  }}
                />
                <InfoBox
                  position={{
                    lat: polygon.points[0].latitude,
                    lng: polygon.points[0].longitude,
                  }}
                >
                  {polygon.label}
                </InfoBox>
              </>
            ))}
          {props.markers &&
            [...props.markers]
              .filter(
                (x) =>
                  !props.withTimeline ||
                  (x.ts > currentTs && x.ts <= currentTs + stepTs * 1)
              )
              .map((marker, index) => (
                <CustomMarker
                  key={`${marker.id || index}`}
                  position={{
                    lat: marker.latitude || marker.lat,
                    lng: marker.longitude || marker.lng,
                  }}
                  onSelectMarker={() => {
                    if (props.onSelectMarker) {
                      props.onSelectMarker(marker);
                    }
                  }}
                  draggable={marker.draggable}
                  icon={marker.icon}
                  marker={marker}
                  label={marker.label}
                  onDragEnd={(e) => {
                    if (marker.onDragEnd) {
                      marker.onDragEnd(marker, e);
                    }
                  }}
                  infoWindow={marker.infoWindow}
                  labelAnchor={{x: 15, y: -5}}
                  labelStyle={{
                    backgroundColor: props.selectedMarkers?.includes(marker.id)
                      ? '#06c'
                      : 'white',
                    color: props.selectedMarkers?.includes(marker.id)
                      ? '#fff'
                      : '444',
                    padding: '3px',
                    borderRadius: '3px',
                    border: '1px solid #eee',
                  }}
                />
              ))}
        </MarkerClusterer>
        {props.blockHeatmap &&
          blocksHeatmap.map((x, index) => (
            <>
              <Polygon
                key={`a${x.id || index}`}
                onClick={() => {
                  if (props.onClickBlockHeatmap) {
                    props.onClickBlockHeatmap(x);
                  }
                }}
                defaultPath={[
                  {lat: x.box.lat1, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng1},
                ]}
                options={{
                  fillColor: x.color || '#ffcc00',
                  strokeColor: 'transparent' || x.color || '#ffcc00',
                  fillOpacity: 0.5,
                  strokeOpacity: 1,
                  strokeWeight: x.strokeWeight || 1,
                  zIndex: 0,
                }}
              />
              <Circle
                key={`b${x.id || index}`}
                onMouseOver={() => {
                  if (x.label) {
                    setMapHover(x.label);
                  }
                }}
                onClick={() => {
                  if (props.onClickBlockHeatmap) {
                    props.onClickBlockHeatmap(x);
                  }
                }}
                defaultCenter={{lat: x.box.latC, lng: x.box.lngC}}
                radius={Math.min(x.radius / 3, 6)}
                defaultPath={[
                  {lat: x.box.lat1, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng1},
                ]}
                options={{
                  fillColor: x.color || '#ffcc00',
                  strokeColor: 'transparent' || x.color || '#ffcc00',
                  fillOpacity: 2,
                  strokeOpacity: 1,
                  strokeWeight: x.strokeWeight || 1,
                }}
              />
              <Circle
                key={`c${x.id || index}`}
                onMouseOver={() => {
                  if (x.label) {
                    setMapHover(x.label);
                  }
                }}
                onClick={() => {
                  if (props.onClickBlockHeatmap) {
                    props.onClickBlockHeatmap(x);
                  }
                }}
                defaultCenter={{lat: x.box.latC, lng: x.box.lngC}}
                radius={x.radius}
                defaultPath={[
                  {lat: x.box.lat1, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng2},
                  {lat: x.box.lat2, lng: x.box.lng1},
                  {lat: x.box.lat1, lng: x.box.lng1},
                ]}
                options={{
                  fillColor: x.color || '#ffcc00',
                  strokeColor: 'transparent' || x.color || '#ffcc00',
                  fillOpacity: x.opacity || 1,
                  strokeOpacity: 1,
                  strokeWeight: x.strokeWeight || 1,
                }}
              />
            </>
          ))}
        {props.heatmap && (
          <HeatmapLayer
            data={props.heatmap.data}
            options={{radius: 20, ...props.heatmap.options}}
          />
        )}
      </GoogleMap>
      {mapHover && (
        <div
          style={{
            position: 'absolute',
            bottom: 3,
            left: 3,
            background: '#fff',
            border: '1px solid #ccc',
            padding: '3px 5px',
            zIndex: 2,
          }}
        >
          {mapHover}
        </div>
      )}
    </div>
  );
});

export default {SimpleMapComponent};
