import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

// @ts-expect-error
import {logout} from '../../helpers/storage';

const LogoutModule = () => {
  const history = useHistory();
  useEffect(() => {
    logout();
    window.setTimeout(() => {
      history.push('/login');
    }, 2000);
  }, []);
  return (
    <div className='h-dvh grid items-center justify-center'>
      <div>Cerrando sesión...</div>
    </div>
  );
};

export default LogoutModule;
